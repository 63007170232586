import { useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/Layout/ProjectLayout.tsx'
import { createApiService } from '@lib/core/api'
import { config } from '@constants'
import { useEffect, useState } from 'react'

import { dateToReadable } from '@lib/utils'
import { ISensorSource } from '@lib/app/sensor'
import { Button, IconName, ProjectDataSourcesTable, Text } from '@components'
import { buildProjectRoute, ProjectDataSourcesRoute, ProjectRoute } from '@lib/app/routing'

export const ProjectDataSourcesPage = () => {
    const { slug } = useParams()
    const navigate = useNavigate()

    function onAddNewSourceClick(): void {
        navigate(
            buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.AddSensor).replace(':slug', slug ?? '')
        )
    }

    const api = createApiService(config.API_BASE_URL)

    const [sensors, setSensors] = useState<ISensorSource[]>(api.cache.sensors)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.context
            .getSensors(slug!)
            .then((sensors) => {
                const mappedSensors = sensors.map((s) => {
                    return {
                        source: s.equipment.name,
                        url: s.id,
                        inputType: s.equipment.eqType,
                        timestamp: dateToReadable(new Date(s.lastUpdated)),
                        dataConfidence: Math.round(s.avgcf * 100) / 100,
                        // Sensor / Sheet
                        dataType: s.equipment.group == 'Manual' ? 'Sheet' : 'Sensor',
                        // Manual / Automatic
                        dataEntry: s.equipment.group == 'Manual' ? 'Manual' : 'Automated',
                    }
                })
                setSensors(mappedSensors)
                setLoading(false)
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        api.cache.setSensors(sensors)
    }, [sensors])

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Data Sources</Text>
                <Button size='medium' icon={IconName.Plus} onClick={onAddNewSourceClick}>
                    Add New Source
                </Button>
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <ProjectDataSourcesTable loading={loading} sensors={sensors} />
        </ProjectLayout>
    )
}
