import { FunctionComponent } from 'react'
import {
    CartesianGrid,
    LineChart as _LineChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { formatDate, Style, truncateString } from '@lib/utils'
import classes from './Chart.module.scss'
import Skeleton from 'react-loading-skeleton'
import { IconName } from '@components/Icon'
import { Link } from '@components/Link'
import { Text } from '@components/Text'
import { CustomTooltip } from './CustomTooltip'
import { IChartData, IChartProps } from './types'
import { cleanChartData } from './utils'

export const LineChart: FunctionComponent<IChartProps> = (props) => {
    const { title, data, start, end, loading = false, height = 245 } = props
    const filteredData = filterDataByDate(data, start, end)

    const textStyle: Style = {
        fontFamily: `var(--font)`,
    }

    function filterDataByDate(data: IChartData[], start: number | Date, end: number | Date) {
        return data.filter((item: { name: string | number | Date }) => {
            const itemDate = new Date(item.name)
            return itemDate >= start && itemDate <= end
        })
    }

    return (
        <div className='bg-[var(--surface-0)] border-[1px] border-[var(--stroke)] shadow-md'>
            {title && (
                <div className='min-h-[72px] flex flex-row items-center bg-[var(--surface-1)] p-4'>
                    <div className='w-full flex flex-row items-center justify-between'>
                        {title.url ? (
                            <Link url={title.url} icon={IconName.ChevronRight} color='text-primary'>
                                <Text type='subtitle1' color='text-primary'>
                                    {truncateString(title.title, 32)}
                                </Text>
                            </Link>
                        ) : (
                            <Text type='subtitle1' color='text-primary'>
                                {truncateString(title.title, 32)}
                            </Text>
                        )}
                        {title.total && title.label && (
                            <div className='flex flex-row items-end justify-between space-x-4 p-0'>
                                <p
                                    className='font-[500] text-[24px] leading-8 align-text-bottom text-[var(--text-primary)]'
                                    style={{ ...textStyle }}
                                >
                                    {title.total}
                                </p>
                                <p
                                    className='font-[600] text-[16px] leading-6 align-text-bottom text-[var(--text-secondary)]'
                                    style={{ ...textStyle }}
                                >
                                    {title.label}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {loading ? (
                <Skeleton height={245} baseColor='white' />
            ) : (
                <div className={classes['sensor-load-container']}>
                    <ResponsiveContainer width='100%' height={height}>
                        <_LineChart
                            width={500}
                            height={300}
                            data={cleanChartData(filteredData)}
                            margin={{
                                top: 8,
                                right: 32,
                                bottom: 40,
                                left: 8,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                type={'category'}
                                domain={['dataMin', 'dataMax']}
                                dataKey='name'
                                fontFamily='SpaceGrotesk'
                                //label={yUnit}
                                dy={10}
                                angle={-45}
                                textAnchor={'end'}
                                fontSize={10}
                                tickFormatter={formatDate}
                            />
                            <YAxis fontFamily='SpaceGrotesk' dx={-5} />
                            <ReferenceLine
                                y={7000}
                                label={{
                                    position: 'left',
                                    value: 'Baseline',
                                    fill: '#0DCE95',
                                    fontFamily: 'SpaceGrotesk',
                                    dy: 15,
                                    dx: 90,
                                    fontSize: 12,
                                }}
                                stroke='#0DCE95'
                                strokeWidth={2}
                                strokeDasharray='10'
                            />
                            <Line type='monotone' dataKey='pv' stroke='#FF00A8' strokeWidth={2} activeDot={{ r: 6 }} />
                            <Tooltip content={<CustomTooltip />} />
                        </_LineChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    )
}
