import { FunctionComponent } from 'react'
import { IconName } from '@components/Icon'
import { config } from '@constants'
import { ProjectAnalyticsRoute, ProjectDataSourcesRoute, ProjectRoute } from '@lib/app/routing'
import { FEATURES } from '@features'
import { SidebarNavigationLink } from './SidebarNavigationLink'
import classes from './SidebarNavigation.module.scss'

interface IProjectSidebarNavigationProps {
    projectSlug: string
}

function buildProjectRoute(
    slug: string,
    route?: ProjectRoute,
    subRoute?: ProjectAnalyticsRoute | ProjectDataSourcesRoute
): string {
    return route ? `/projects/${slug}/${route}${subRoute ? '/' + subRoute : ''}` : `/projects/${slug}`
}

export const ProjectSidebarNavigation: FunctionComponent<IProjectSidebarNavigationProps> = (props) => {
    const { projectSlug } = props
    const optionalPages = [FEATURES.project.guardian]
    const isOneOptionalPageEnabled = optionalPages.some((page) => page.enabled)

    return (
        <nav className={classes['navigation']}>
            {FEATURES.project.dashboard.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Dashboard)}
                    icon={IconName.Dashboard}
                    text='Dashboard'
                />
            )}
            {FEATURES.project.analytics.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Analytics)}
                    icon={IconName.Analytics}
                    text='Analytics'
                />
            )}
            {FEATURES.project.dataSources.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.DataSources)}
                    icon={IconName.Cpu}
                    text='Data Sources'
                    svgProperties={['stroke']}
                />
            )}
            {FEATURES.project.documentation.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Documentation)}
                    icon={IconName.Document}
                    text='Documentation'
                    svgProperties={['stroke']}
                />
            )}
            {FEATURES.project.activity.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Activity)}
                    icon={IconName.Activity}
                    text='Activity'
                    svgProperties={['stroke']}
                />
            )}
            {config.DEMO_MODE ? (
                <></>
            ) : (
                <>
                    <SidebarNavigationLink
                        url={buildProjectRoute(projectSlug, ProjectRoute.Users)}
                        icon={IconName.Users}
                        text='Users'
                        svgProperties={['stroke']}
                    />
                    {!FEATURES.project.dataSources.enabled && (
                        <SidebarNavigationLink
                            url={buildProjectRoute(
                                projectSlug,
                                ProjectRoute.DataSources,
                                ProjectDataSourcesRoute.AddSensor
                            )}
                            icon={IconName.Cpu}
                            text='Add Sensor'
                            svgProperties={['stroke']}
                        />
                    )}
                </>
            )}
            {isOneOptionalPageEnabled && (
                <>
                    <div className='border-b-[1px] border-[var(--stroke)]'></div>
                    {FEATURES.project.guardian.enabled && (
                        <SidebarNavigationLink
                            url={buildProjectRoute(projectSlug, ProjectRoute.Guardian)}
                            icon={IconName.Guardian}
                            text='Guardian'
                            svgProperties={['stroke']}
                        />
                    )}
                </>
            )}
        </nav>
    )
}
