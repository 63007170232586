import { useEffect, useState } from 'react'
import { Button, Pane, ProfileLayout, Text } from '@components'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'

export const IdentityPage = () => {
    const api = createApiService(config.API_BASE_URL)

    const [_, setLoading] = useState(true)
    const [identity, setIdentity] = useState<any | null>(null)
    const [message, setMessage] = useState<string>('')
    const [deleteDid, setDeleteDid] = useState(false)

    const getIdentity = async () => {
        await api.user
            .getIdentity(false)
            .then((response) => {
                if (response && response['doc_id']) {
                    setMessage('')
                    setIdentity(response['doc_id'])
                }
            })
            .catch((error) => {
                setMessage(error.toString())
                setDeleteDid(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getIdentity()
    }, [])

    const onCreateClick = async () => {
        setMessage('Processing Request... Please wait')
        await api.user
            .createIdentity()
            .then((response) => {
                setMessage('')
                setIdentity(response)
            })
            .catch((error) => {
                setMessage(error.toString())
            })
    }

    const onDeleteClick = async () => {
        setMessage('Processing Request... Please wait')
        await api.user
            .deleteIdentity()
            .then((_) => {
                setMessage('')
                setDeleteDid(false)
                setIdentity(null)
            })
            .catch((error) => {
                setMessage(error.toString())
            })
    }

    return (
        <ProfileLayout title='Identity'>
            <Pane>
                {identity && (
                    <div id='identityContainer' className='flex flex-col space-y-4'>
                        <Text type='body1'>Found identity:</Text>
                        <Text type='body1'>{identity.toString()}</Text>
                        <div>
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        </div>
                    </div>
                )}
                {!identity && (
                    <div id='createIdentityContainer' className='flex flex-col space-y-4'>
                        <Text type='header3'>Create an Identity</Text>
                        {!deleteDid && (
                            <Button size='large' onClick={onCreateClick}>
                                Create
                            </Button>
                        )}
                        {deleteDid && (
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        )}
                        <Text type='body2'>{message}</Text>
                    </div>
                )}
            </Pane>
        </ProfileLayout>
    )
}
