import { FunctionComponent, ReactNode } from 'react'
import { OutsideAlerter } from '@components/Popover'
import classes from './Popup.module.scss'

interface IPopupProps {
    children: ReactNode
    onClose: () => void
}

export const Popup: FunctionComponent<IPopupProps> = (props) => {
    const { children, onClose } = props
    return (
        <div className={classes.popupOverlay}>
            <div className={classes.popup}>
                <OutsideAlerter onClick={onClose}>
                    <div className={classes.popupContent}>{children}</div>
                </OutsideAlerter>
            </div>
        </div>
    )
}
