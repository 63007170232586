import { ReadingDashboardContext } from '@utils/types/ProjectDetails.ts'
import { ISensorData } from './sensor.types.ts'

export function getSensorDataFromDashboardContext(context: ReadingDashboardContext, unit: string): ISensorData {
    return {
        timestamp: new Date(context.timestamp),
        value: context.value,
        address: context.address,
        annotations: Array.from(Object.values(context.annotations)),
        score: parseInt((context.score * 100).toFixed()),
        unit,
    }
}
