import { FunctionComponent } from 'react'
import { ReactSVG } from 'react-svg'
import {
    actionsIcon,
    allProjectsIcon,
    chart,
    exportIcon,
    plusIcon,
    deleteTrash,
    infoIcon,
    sustainability,
    fileIconBig,
    smallArrowDown,
    smallArrowUp,
    sortIconDefault,
    notificationsIcon,
    searchIcon,
    uploadIcon,
    dashboardIcon,
    dataSourcesIcon,
    usersIcon,
    activityIcon,
    documentationIcon,
    analyticsIcon,
    projectIcon,
    userIcon,
    guardianIcon,
    sunIcon,
    moonIcon,
    arrowRight,
    clockIcon,
    arrowLeft,
    settingsIcon,
    hamburgerIcon,
} from '@assets'
import classes from './Icon.module.scss'

interface IconProps {
    name: IconName
    color?: string
}

export enum IconName {
    Actions = 'actions',
    Activity = 'activity',
    Alert = 'alert',
    Analytics = 'analytics',
    ArrowLeft = 'arrow-left',
    ArrowRight = 'arrow-right',
    Chart = 'chart',
    ChevronDown = 'chevron-down',
    ChevronRight = 'chevron-right',
    ChevronSelectorVertical = 'chevron-selector-vertical',
    ChevronUp = 'chevron-up',
    Clock = 'clock',
    Cpu = 'cpu',
    Dashboard = 'dashboard',
    Document = 'document',
    Export = 'export',
    Folder = 'folder',
    Guardian = 'guardian',
    Hamburger = 'hamburger',
    Info = 'info',
    Location = 'location',
    Moon = 'moon',
    Notifications = 'notifications',
    Plus = 'plus',
    Search = 'search',
    Settings = 'settings',
    Sun = 'sun',
    Sustainability = 'sustainability',
    ThreeHorizontalLines = 'three-horizontal-lines',
    Trash = 'trash',
    Upload = 'upload',
    User = 'user',
    Users = 'users',
}

const ICON_MAP: Record<IconName, string> = {
    [IconName.Actions]: actionsIcon,
    [IconName.Activity]: activityIcon,
    // TODO: Needs to use an "exclamation" icon rather than "info"
    [IconName.Alert]: infoIcon,
    [IconName.Analytics]: analyticsIcon,
    [IconName.ArrowLeft]: arrowLeft,
    [IconName.ArrowRight]: arrowRight,
    [IconName.Chart]: chart,
    [IconName.ChevronDown]: smallArrowDown,
    [IconName.ChevronRight]: arrowRight,
    [IconName.ChevronSelectorVertical]: sortIconDefault,
    [IconName.ChevronUp]: smallArrowUp,
    [IconName.Clock]: clockIcon,
    [IconName.Cpu]: dataSourcesIcon,
    [IconName.Dashboard]: dashboardIcon,
    [IconName.Document]: documentationIcon,
    [IconName.Export]: exportIcon,
    [IconName.Folder]: fileIconBig,
    [IconName.Guardian]: guardianIcon,
    [IconName.Hamburger]: hamburgerIcon,
    [IconName.Info]: infoIcon,
    [IconName.Location]: projectIcon,
    [IconName.Moon]: moonIcon,
    [IconName.Notifications]: notificationsIcon,
    [IconName.Plus]: plusIcon,
    [IconName.Search]: searchIcon,
    [IconName.Settings]: settingsIcon,
    [IconName.Sun]: sunIcon,
    [IconName.Sustainability]: sustainability,
    [IconName.ThreeHorizontalLines]: allProjectsIcon,
    [IconName.Trash]: deleteTrash,
    [IconName.Upload]: uploadIcon,
    [IconName.User]: userIcon,
    [IconName.Users]: usersIcon,
}

export function getIconFromName(name: IconName): string | undefined {
    if (name in ICON_MAP) {
        return ICON_MAP[name]
    } else {
        return undefined
    }
}

/**
 * Renders an SVG-based icon.
 *
 * @param props
 */
export const Icon: FunctionComponent<IconProps> = (props) => {
    const { name, color } = props
    if (name in ICON_MAP) {
        return <ReactSVG src={ICON_MAP[name]} className={`${classes[`color-${color ?? 'black'}`] ?? ''}`}></ReactSVG>
    } else {
        return <></>
    }
}
