import { useNavigate, useParams } from 'react-router-dom'
import { Button, Icon, IconName, LineChart, LineAreaChart, Link, Pane, ProjectLayout, ProjectStatistic, Text } from '@components'
import classes from './ProjectAnalyticsPage.module.scss'
import '../CalendarStyle.scss'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { createApiService } from '@lib/core/api'
import { config } from '@constants'
import { IProjectStateData, IProjectStateValueSet, IProjectStatistic } from '@lib/app/project'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { DashboardPropsType } from '@utils/types/ProjectDetails.ts'
import { AppRoute, buildProjectRoute, buildRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/app/routing'

const statisticsData = [
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'Annual GHG emission reductions',
    },
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'GHG emission reductions last 30 day',
    },
    {
        type: 'chart',
        amount: '',
        unit: '%',
        text: 'Data Confidence',
    },
]

export const ProjectAnalyticsPage = () => {
    const { slug } = useParams()
    const api = createApiService(config.API_BASE_URL)
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup).replace(':slug', slug ?? '')
    const navigate = useNavigate()

    function onSettingsClick() {
        navigate(url)
    }

    const [isVisible, setIsVisible] = useState(false)
    const [analytics, setAnalytics] = useState<IProjectStateData | null>(null)
    const [firstChart, setFirstChart] = useState<IProjectStateValueSet | null>(null)
    const [firstChartTitle] = useState<string>('e_project')

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [startDate, setStartDate] = useState(thirtyDaysAgo)
    const [endDate, setEndDate] = useState(new Date())

    const [statistics, setStatistics] = useState<IProjectStatistic[]>([
        { ...statisticsData[0] },
        { ...statisticsData[1] },
        { ...statisticsData[2] },
    ])
    const [project, setProject] = useState<DashboardPropsType | null>(null)

    useEffect(() => {
        const getProject = async () => {
            return slug
                ? await api.context.getSite(slug).then((response) => {
                      setProject(response)
                  })
                : new Error('Not a known project id')
        }

        const getInitialAnalytics = async () => {
            return slug
                ? await api.context.getAnalytics(slug).then((response) => {
                      // TODO: Define a user front end dependent state for FirstChart so they can choose which chart rests up top
                      const eProject = Object.entries(response).find(([label]) => label === firstChartTitle)
                      setFirstChart(eProject ? eProject[1] : null)
                      setAnalytics(
                          Object.fromEntries(
                              // TODO: Remove calc data from forwarded state, Records can/should be separate from analytics profile
                              Object.entries(response).filter(([label]) => label !== 'calc_data')
                          )
                      )
                  })
                : new Error('No analytics response')
        }

        getProject()
            .then(() => getInitialAnalytics())
            .catch((err) => {
                console.error(err)
                navigate(buildRoute(AppRoute.SignIn))
            })
    }, [slug])

    const parseValue = (value: string) => {
        const parsed = parseFloat(value)
        return parseFloat(parsed.toFixed(0)).toLocaleString()
    }

    useEffect(() => {
        if (project) {
            const ghgAnnual = {
                type: 'sustainability',
                amount: parseValue(project.ghgAnnual.value),
                unit: project.ghgAnnual.unit,
                text: 'Annual GHG emissions reductions (est)',
            }

            const ghg30Day = {
                type: 'sustainability',
                amount: parseValue(project.ghg30Day.value),
                unit: project.ghg30Day.unit,
                text: 'GHG emissions reductions last 30 days',
            }

            const dcf = {
                type: 'chart',
                amount: project.avgDcf ? project.avgDcf : '0',
                unit: '%',
                text: 'Data Confidence',
            }

            setStatistics([ghgAnnual, ghg30Day, dcf])
        }
    }, [project])

    const exportButtonHandler = () => {
        setIsVisible((prev) => !prev)
    }

    function onExportClick(fileType: 'csv' | 'xlsv'): void {
        console.log(fileType)
    }

    const TitleComponent = () => {
        return (
            <div className='w-full flex flex-row items-center justify-between relative'>
                <Text type='header2'>Analytics</Text>
                <div className='flex flex-row items-center space-x-4'>
                    <Button variant='outline' icon={IconName.Settings} onClick={onSettingsClick}>
                        Settings
                    </Button>
                    <div className='flex flex-row items-center bg-[var(--white)]'>
                        <div className={`flex flex-row items-center ${classes.datepicker}`}>
                            <DatePicker
                                calendarClassName='calendarStyle'
                                popperClassName='pooperStyle'
                                selected={startDate}
                                onChange={(date) => date && setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                dateFormat='MMMM d, yyyy'
                            />
                            <span>-</span>
                            <DatePicker
                                calendarClassName='calendarStyle'
                                popperClassName='pooperStyle'
                                selected={endDate}
                                onChange={(date) => date && setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={new Date()}
                                dateFormat='MMMM d, yyyy'
                            />
                        </div>
                        <span className={classes.divider}></span>
                        <div className={classes.moreOptions} onClick={exportButtonHandler}>
                            <Icon name={IconName.Hamburger} />
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className={classes.export}>
                        <div className={classes.exportWrapper}>
                            <div className='mb-8'>
                                <Text type='header3'>Export</Text>
                            </div>
                            <div className='w-full flex flex-col items-end justify-between space-y-4'>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('csv')}
                                >
                                    Export to CSV
                                </Button>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('xlsv')}
                                >
                                    Export to XLSV
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <div className={classes.analytics}>
                <Pane>
                    <div className={classes.innerContent}>
                        {/* first main chart here */}
                        <div className={classes.analyticsGreenChart}>
                            <div className={classes.heading}>
                                <div className={classes.projectLink}>
                                    {firstChart ? (
                                        <Link url={firstChartTitle} icon={IconName.ChevronRight} color='text-primary'>
                                            {firstChart.title}
                                        </Link>
                                    ) : (
                                        <Skeleton />
                                    )}
                                </div>
                                <div>
                                    <h2>{firstChart?.total.toFixed(3) || <Skeleton />}</h2>
                                    <span>{firstChart?.label || <Skeleton />}</span>
                                </div>
                            </div>
                            <LineChart
                                data={
                                    firstChart
                                        ? Object.values(firstChart.values).map((val, index) => {
                                              return { name: firstChart.timestamps[index], uv: val, pv: val, amt: val }
                                          })
                                        : []
                                }
                                start={startDate}
                                end={endDate}
                                loading={firstChart === null}
                            />
                        </div>
                        <div className={classes.statisticWrapper}>
                            {statistics.map((data: any) => (
                                <ProjectStatistic size='small' statistic={data} />
                            ))}
                        </div>
                        {/* chart grid */}
                        <div className={classes.grid}>
                            {analytics &&
                                Object.entries(analytics)
                                    .filter(([label]) => label !== firstChartTitle)
                                    .map(([id, item]) => (
                                        <LineAreaChart
                                            title={{
                                                title: item.title,
                                                total: item.total.toFixed(3),
                                                label: item.label,
                                                url: id,
                                            }}
                                            data={Object.values(item.values).map((val, index) => {
                                                return { name: item.timestamps[index], uv: val, pv: val, amt: val }
                                            })}
                                            start={startDate}
                                            end={endDate}
                                            height={200}
                                        />
                                    ))}
                        </div>
                    </div>
                </Pane>
            </div>
        </ProjectLayout>
    )
}
