import { FunctionComponent } from 'react'
import { IDemiaNotification } from '@utils/types/ProjectDetails.ts'
import { ProfileAvatar, Text } from '@components'
import { formatDuration, truncateString } from '@lib/utils'

export const ProjectUserActivity: FunctionComponent<IDemiaNotification> = (item) => {
    const { user, message, timestamp } = item
    return (
        <div className='flex items-start justify-between'>
            <div className='flex flex-row space-x-2 items-center flex-1 max-w-[175px]'>
                <ProfileAvatar initials={user.substring(0, 2)} />
                <div className='flex flex-col space-y-1 items-start break-words whitespace-nowrap'>
                    <Text type='body1' color='grey'>
                        {truncateString(user, 16)}
                    </Text>
                    <Text type='body1'>{truncateString(message, 24)}</Text>
                </div>
            </div>
            <div className='self-center justify-self-end whitespace-nowrap'>
                <Text type='body2'>{formatDuration(timestamp.getTime())}</Text>
            </div>
        </div>
    )
}
