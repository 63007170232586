import { useLocation, useParams } from 'react-router-dom'
import classes from './CargoSensor.module.scss'
import { cargoAverage, cargoCondidence, cargoCurrent, grapeHarvest, greenWaste } from '@assets'
import { FunctionComponent, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../CalendarStyle.scss'
import { SensorDashboardContext } from '@utils/types/ProjectDetails.ts'
import { dateToReadable } from '@lib/utils'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
    BarChart,
    Button,
    Icon,
    IconName,
    Link,
    Pane,
    ProjectLayout,
    ProjectSensorDataTable,
    Tabs,
    Text,
} from '@components'
import { getSensorDataFromDashboardContext, ISensorData } from '@lib/app/sensor'

/*const loadInfo = {
  source: "2023 Ford Transit Van #13",
  lastUpdate: "10/11/2023",
  unit: "2,4 kg",
};*/

const cargoDataEmpty: Array<{
    img: string
    text: string
    value: number
    valueUnit: string
}> = [
    {
        img: cargoCurrent,
        text: 'Current Load',
        value: 0,
        valueUnit: 'Kg',
    },
    {
        img: cargoAverage,
        text: 'Daily Average Load',
        value: 0,
        valueUnit: 'Kg',
    },
    {
        img: cargoCondidence,
        text: 'Data Confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const CargoSensor: FunctionComponent<{ sensor: SensorDashboardContext | undefined }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const [isVisible, setIsVisible] = useState(false)

    const exportButtonHandler = () => {
        setIsVisible((prev) => !prev)
    }

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [sensor, setSensor] = useState<SensorDashboardContext>()
    const [cargoData, setCargoData] = useState<
        Array<{
            img: string
            text: string
            value: number
            valueUnit: string
        }>
    >([{ ...cargoDataEmpty[0] }, { ...cargoDataEmpty[1] }, { ...cargoDataEmpty[2] }])

    const [tableList, setTableList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<
        Array<{
            name: string
            uv: number
            pv: number
            amt: number
        }>
    >([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensor) {
            setGraphData(
                Array.from(Object.values(sensor.sensor.readings)).map((v) => {
                    return {
                        name: v.timestamp,
                        uv: v.value,
                        pv: v.value,
                        amt: v.value,
                    }
                })
            )

            const readings = Array.from(Object.values(sensor.sensor.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensor.sensor.equipment.units)
            })

            readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
            setTableList(readings)
            setLoading(false)
        }
    }, [sensor])

    useEffect(() => {
        if (props.sensor) {
            setSensor(props.sensor)
            const numbers = [...cargoData]
            numbers[0].value = Math.round(props.sensor.stateData.realTimeFlow)
            numbers[0].valueUnit = props.sensor.sensor.equipment.units
            numbers[1].value = Math.round(props.sensor.stateData.currentDayAvg)
            numbers[1].valueUnit = props.sensor.sensor.equipment.units
            numbers[2].value = Math.round(props.sensor.sensor.avgcf)
            setCargoData(numbers)
        }
    }, [props.sensor])

    function onExportClick(fileType: 'csv' | 'xlsv'): void {
        console.log(fileType)
    }

    const TitleComponent = () => {
        return (
            <div className='w-full flex flex-row items-center justify-between relative'>
                <Link url={url} icon={IconName.ArrowLeft} color='text-primary' reverse>
                    <Text type='header2'>{sensor ? sensor.sensor.equipment.name : 'Cargo sensor'}</Text>
                </Link>
                {activeTab === 1 && (
                    <Button variant='outline' size='medium' onClick={exportButtonHandler}>
                        <Icon name={IconName.Hamburger} />
                    </Button>
                )}
                {isVisible && (
                    <div className={classes.export}>
                        <div className={classes.exportWrapper}>
                            <div className='mb-8'>
                                <Text type='header3'>Export</Text>
                            </div>
                            <div className='w-full flex flex-col items-end justify-between space-y-4'>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('csv')}
                                >
                                    Export to CSV
                                </Button>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('xlsv')}
                                >
                                    Export to XLSV
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className='flex flex-col space-y-8'>
                    <div className='flex flex-row items-center justify-between space-x-4 p-8 clipped bg-[var(--surface-transparent)]'>
                        <div className='max-w-[320px]'>
                            {sensor ? (
                                sensor.sensor.id === 'Grape Harvest' ? (
                                    <img className='aspect-square object-cover' src={grapeHarvest} alt='grapeHarvest' />
                                ) : (
                                    <img className='aspect-square object-cover' src={greenWaste} alt='greenWaste' />
                                )
                            ) : (
                                <Skeleton width={320} height={320} />
                            )}
                        </div>
                        <div className='w-full flex flex-col space-y-4 justify-between'>
                            <div className='w-full flex flex-row items-center justify-between space-x-4'>
                                {cargoData.map((data, index) => (
                                    <div
                                        className='w-1/3 h-full bg-[var(--white)] border-[1px] border-[var(--stroke-light)] flex flex-col items-center justify-between p-6 space-y-4'
                                        key={index}
                                    >
                                        <img src={data.img} alt='Image' />
                                        <Text type='body1' color='grey'>
                                            {data.text}
                                        </Text>
                                        <h5 className='font-32-28-500'>
                                            {data.value}{' '}
                                            <span className='font-24-28-400 text-[var(--grey)]'>{data.valueUnit}</span>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            <div className='w-full bg-[var(--white)] border-[1px] border-[var(--stroke-light)] p-6'>
                                <Text type='header3'>Solid Waste Load</Text>
                                <div className='w-full flex flex-row items-center justify-between mt-6'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='grey'>
                                            Source:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensor?.sensor.equipment.id || <Skeleton width={64} />}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='grey'>
                                            Last updated:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensor ? (
                                                dateToReadable(new Date(sensor.sensor.lastUpdated))
                                            ) : (
                                                <Skeleton width={64} />
                                            )}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='grey'>
                                            Units:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensor?.sensor.equipment.units || <Skeleton width={64} />}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        <div className='p-2'>
                            <BarChart
                                title={sensor && { title: sensor.sensor.equipment.name }}
                                data={graphData}
                                loading={!sensor}
                            />
                        </div>
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={tableList} />
            </Tabs>
        </ProjectLayout>
    )
}
