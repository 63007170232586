import { FunctionComponent, useState } from 'react'
import { Link, LinkTarget } from '@components/Link'
import { ITableRowProps, Table, TableRow, TableTitle } from '@components/Table'
import { Text } from '@components/Text'
import { config } from '@constants'
import { ISensorData } from '@lib/app/sensor'
import { dateToReadable } from '@lib/utils'
import {
    PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS,
    ProjectSensorAnnotationTableRow,
} from '@components/Project/ProjectSensorAnnotationTableRow.tsx'
import { Pane } from '@components'

export const ProjectSensorDataTableRow: FunctionComponent<ITableRowProps<ISensorData>> = (props) => {
    const { timestamp, value, unit, score, address, annotations } = props?.data ?? ({} as ISensorData)
    const [showAnnotations, setShowAnnotations] = useState<boolean>(false)

    const cells = [
        <div onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{dateToReadable(timestamp)}</Text>
        </div>,
        <div onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>
                {value} {unit}
            </Text>
        </div>,
        <div onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{score}</Text>
        </div>,
        <Link url={`${config.EXPLORER_URL}/search/${address}`} target={LinkTarget.Blank}>
            {address.slice(0, 5)}...{address.slice(-24)}
        </Link>,
    ]

    const annotationTable = [
        <div className='absolute z-[100] w-[70vw] bg-[var(--light-gray)]'>
            <Pane unClipped={true}>
                <Text type='header4'>Annotations</Text>
                <div className='overflow-x-auto bg-[var(--white)]'>
                    <Table
                        TitleTemplate={() => TableTitle({ title: 'Annotations' })}
                        fields={PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS}
                        data={annotations}
                        RowTemplate={ProjectSensorAnnotationTableRow}
                        unClipped={true}
                    ></Table>
                </div>
            </Pane>
        </div>,
    ]

    return !showAnnotations ? (
        <TableRow>{cells.map((cell) => cell)}</TableRow>
    ) : (
        <>
            <TableRow>{cells.map((cell) => cell)}</TableRow>
            <TableRow>{annotationTable.map((cell) => cell)}</TableRow>
        </>
    )
}
