import { FunctionComponent } from 'react'
import { ITableField, Table } from '@components/Table'
import { SortDirection, sortPrimitives } from '@lib/utils'
import { IDemiaNotification } from '@utils/types/ProjectDetails.ts'
import { ProjectActivityTableRow } from './ProjectActivityTableRow'

interface IProjectActivityTableProps {
    loading: boolean
    activities: IDemiaNotification[]
}

const PROJECT_ACTIVITY_TABLE_FIELDS: ITableField[] = [
    {
        text: 'User',
        key: 'user',
        sortingFunction: (a: IDemiaNotification, b: IDemiaNotification, direction: SortDirection) =>
            sortPrimitives(a.user, b.user, direction),
    },
    {
        text: 'Message',
        key: 'message',
        sortingFunction: (a: IDemiaNotification, b: IDemiaNotification, direction: SortDirection) =>
            sortPrimitives(a.message, b.message, direction),
    },
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: IDemiaNotification, b: IDemiaNotification, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
        sortByDefault: true,
    },
]

export const ProjectActivityTable: FunctionComponent<IProjectActivityTableProps> = (props) => {
    const { loading, activities } = props
    return (
        <Table
            fields={PROJECT_ACTIVITY_TABLE_FIELDS}
            data={activities}
            RowTemplate={ProjectActivityTableRow}
            loading={loading}
            disableSorting
        />
    )
}
