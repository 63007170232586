import { FunctionComponent } from 'react'
import { ITableRowProps, TextTableRow } from '@components/Table'
import type { IIdentityMethod } from '@lib/app/identity'
import { truncateString } from '@lib/utils'

interface IIdentityTableRowProps {
    hiddenColumns?: string[]
}

export const IdentityTableRow: FunctionComponent<IIdentityTableRowProps & ITableRowProps<IIdentityMethod>> = (
    props
) => {
    const { data, hiddenColumns = [] } = props ?? {}
    const { id = '', fragment = '', type_ = '', controller = '' } = data ?? {}

    return (
        <TextTableRow
            data={[
                ...(hiddenColumns.includes('id') ? [] : [truncateString(id, 32)]),
                ...(hiddenColumns.includes('fragment') ? [] : [fragment]),
                ...(hiddenColumns.includes('type') ? [] : [type_]),
                ...(hiddenColumns.includes('controller') ? [] : [truncateString(controller, 32)]),
            ]}
        />
    )
}
