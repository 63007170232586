import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Button,
    IconName,
    ProjectDocumentationPopup,
    ProjectDocumentationTable,
    ProjectLayout,
    Tabs,
    Text,
} from '@components'
import { config } from '@constants'
import { IProjectDocument, ProjectDocumentType } from '@lib/app/project'
import { createApiService } from '@lib/core/api'
import { IFile } from '@lib/utils'

const DEFAULT_PROJECT_FILES: Record<ProjectDocumentType, IProjectDocument[]> = {
    [ProjectDocumentType.Project]: [],
    [ProjectDocumentType.Monitoring]: [],
    [ProjectDocumentType.Report]: [],
    [ProjectDocumentType.Certification]: [],
}

export const ProjectDocumentationPage = () => {
    const api = createApiService(config.API_BASE_URL)
    const { slug } = useParams()

    const [selectedProjectDocumentType, _] = useState<ProjectDocumentType>(ProjectDocumentType.Project)
    const [projectDocuments, setProjectDocuments] =
        useState<Record<ProjectDocumentType, IProjectDocument[]>>(DEFAULT_PROJECT_FILES)
    const [loading, setLoading] = useState(true)

    function loadDocumentsFromFiles(files: IFile[]): void {
        projectDocuments[selectedProjectDocumentType] = files.map((file) => {
            const document = projectDocuments[selectedProjectDocumentType].find(
                (document) => document.file.name === file.name
            )
            if (document) {
                return {
                    ...document,
                    file: {
                        ...file,
                        lastModified: new Date(file.lastModified),
                    },
                }
            } else {
                return {
                    file: {
                        ...file,
                    },
                }
            }
        })
        setProjectDocuments(projectDocuments)
    }

    useEffect(() => {
        api.context
            .listFiles(slug!)
            .then((res) => {
                loadDocumentsFromFiles(res.files)
                setLoading(false)
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
    }, [])

    async function downloadDocument(document: IProjectDocument): Promise<void> {
        try {
            const { file } = document
            const res = await api.context.downloadFile(slug!, file.name)
            const link = window.document.createElement('a')
            link.download = `${file.name}`
            const blob = new Blob([res], { type: file.metadata?.type || 'text/plain' })
            link.href = URL.createObjectURL(blob)
            link.click()
            URL.revokeObjectURL(link.href)
        } catch (err) {
            console.error(err)
        }
    }

    async function deleteDocument(document: IProjectDocument) {
        try {
            //let _res = await api.context.deleteFile(slug!, file.name);
            projectDocuments[selectedProjectDocumentType] = projectDocuments[selectedProjectDocumentType].filter(
                (_document) => _document.file.name !== document.file.name
            )
            setProjectDocuments(projectDocuments)
        } catch (err) {
            console.error(err)
        }
    }

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

    function openModal(): void {
        setIsPopupOpen(true)
    }

    function onClosePopup(newDocuments: IProjectDocument[]): void {
        setIsPopupOpen(false)
        if (newDocuments.length > 0) {
            projectDocuments[selectedProjectDocumentType] = newDocuments.concat(
                projectDocuments[selectedProjectDocumentType]
            )
            setProjectDocuments(projectDocuments)
        }
    }

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Documentation</Text>
                <Button size='medium' onClick={openModal} icon={IconName.Plus}>
                    Add New
                </Button>
            </>
        )
    }

    return (
        <>
            <ProjectLayout TitleComponent={TitleComponent}>
                <Tabs tabHeaders={['Project Documents', 'Monitoring Plans', 'Reports', 'Certifications']}>
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Project]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Monitoring]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Report]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                    <ProjectDocumentationTable
                        loading={loading}
                        documents={projectDocuments[ProjectDocumentType.Certification]}
                        downloadFn={downloadDocument}
                        deleteFn={deleteDocument}
                        hideEntity
                    />
                </Tabs>
            </ProjectLayout>
            {isPopupOpen && <ProjectDocumentationPopup onClose={onClosePopup} />}
        </>
    )
}
