import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, IconName, ProfileLayout, ProjectDetails, ProjectDetailSkeleton } from '@components'
import { Loading } from '@components/Loading/Loading.tsx'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { OverviewPropsType } from '@utils/types/ProjectDetails.ts'
import classes from './OverviewPage.module.scss'

export const OverviewPage: FunctionComponent = () => {
    const [projectsInfo, setProjectsInfo] = useState<OverviewPropsType>()
    const api = createApiService(config.API_BASE_URL)

    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        const fetchProjects = async () => {
            await api.context.getProjects().then((response) => {
                setProjectsInfo(response)
                if (response.sites.length === 0) {
                    const timeout = 1000
                    setReload(true)
                    setTimeout(() => {
                        console.log('Fetching projects again')
                        fetchProjects()
                    }, timeout)
                }

                return response
            })
        }

        console.log('fetching projects')
        fetchProjects()
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
        console.log('reload', reload)
    }, [])

    const navigate = useNavigate()

    function onAddNewProjectClick() {
        navigate('/add-stream')
    }

    return loading ? (
        <Loading />
    ) : (
        <ProfileLayout title='Overview'>
            <>
                {!projectsInfo && <ProjectDetailSkeleton />}
                {projectsInfo?.sites && projectsInfo?.sites?.length === 0 && reload && <ProjectDetailSkeleton />}
                {projectsInfo?.sites && projectsInfo?.sites?.length === 0 && !config.DEMO_MODE && (
                    <article className={classes.projectDetails}>
                        <Button size='large' icon={IconName.Plus} onClick={onAddNewProjectClick}>
                            Add New Project
                        </Button>
                    </article>
                )}
                {projectsInfo?.sites && projectsInfo?.sites?.map((i, index) => <ProjectDetails key={index} {...i} />)}
            </>
        </ProfileLayout>
    )
}
