import { FunctionComponent } from 'react'

interface IProgressBarProps {
    progress: number
}

export const ProgressBar: FunctionComponent<IProgressBarProps> = (props) => {
    const { progress = 0 } = props
    return (
        <div className='flex flex-row items-center space-x-2'>
            <div className='w-[100px] h-[10px] bg-[var(--stroke)]'>
                <div
                    className='h-[10px] bg-[var(--light-green)] text-right'
                    style={{ width: Math.max(0, Math.min(progress, 100)) }}
                ></div>
            </div>
            <p>{Math.max(0, Math.min(progress, 100))}</p>
        </div>
    )
}
