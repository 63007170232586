import { formatDate } from '@lib/utils'
import { Text } from '@components'
import { FunctionComponent } from 'react'
import { TooltipProps } from 'recharts'

interface ITooltipProps extends TooltipProps<string, string> {
    isRange?: boolean
}

export const CustomTooltip: FunctionComponent<ITooltipProps> = (props) => {
    const { isRange = false, payload = [], label, active } = props
    if (active && payload.length > 0) {
        return (
            <div className='px-6 py-4 text-center shadow-md bg-[var(--surface-0)]'>
                <Text type='subtitle2' color='text-primary'>
                    {formatDate(label)}
                </Text>
                {isRange ? (
                    <>
                        <Text type='header3' color='text-primary'>
                            Min: {payload[0].payload.uv}
                        </Text>
                        <Text type='header3' color='text-primary'>
                            Max: {payload[0].payload.pv}
                        </Text>
                    </>
                ) : (
                    <Text type='header3' color='text-primary'>
                        {payload[0].value as string}
                    </Text>
                )}
            </div>
        )
    }
    return null
}
