import { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, ProjectLayout, Pane, PasswordInput, TextInput } from '@components'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { IHederaProfile } from '@lib/app/hedera'
import Skeleton from 'react-loading-skeleton'

export const RegisterProjectGuardianPage: FunctionComponent = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const api = createApiService(config.API_BASE_URL)
    const [loading, setLoading] = useState<boolean>(false)
    const [hederaProfile, setHederaProfile] = useState<IHederaProfile | null>(null)

    useEffect(() => {
        setLoading(true)
        api.user
            .hederaProfile()
            .then((profile) => {
                setHederaProfile(profile)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    async function onLinkGuardianUser(event: FormEvent<HTMLFormElement>) {
        if (loading) {
            return
        } else {
            setLoading(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                username: { value: string }
                password: { value: string }
            }

            await api.user
                .hederaLink(form.username.value, form.password.value)
                .then((profile) => {
                    setHederaProfile(profile)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    function onAddProjectGuardianSubmit(event: FormEvent<HTMLFormElement>) {
        if (loading) {
            return
        } else {
            setLoading(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                operatorId: { value: string }
                operatorKey: { value: string }
                policy: { value: string }
            }

            api.hedera
                .registerHederaGuardian(slug ?? '', {
                    operatorId: form.operatorId.value,
                    operatorKey: form.operatorKey.value,
                    policy: form.policy.value,
                })
                .then(() => {
                    navigate(`/projects/${slug}/guardian`)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    return (
        <ProjectLayout title='Register Guardian'>
            <Pane>
                {!hederaProfile ? (
                    loading ? (
                        <Skeleton width={192} height={18} />
                    ) : (
                        <div className='flex flex-col space-y-4'>
                            <h3>Link your Hedera Guardian account</h3>
                            <form
                                onSubmit={onLinkGuardianUser}
                                title={'Link your Hedera Guardian Account'}
                                className='flex flex-col space-y-4'
                            >
                                <TextInput id='username' label='Username' required autofocus />
                                <PasswordInput id='password' label='Password' required />
                                <div className='pt-4'>
                                    <Button type='submit' width='1/2' onClick={() => {}}>
                                        {loading ? 'Linking...' : 'Link Guardian'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )
                ) : (
                    <form onSubmit={onAddProjectGuardianSubmit} className='flex flex-col space-y-4'>
                        <TextInput id='operatorId' label='Operator ID' required />
                        <PasswordInput id='operatorKey' label='Operator Key' required />
                        <TextInput id='policy' label='Policy' required />
                        <div className='pt-4'>
                            <Button type='submit' width='1/2' onClick={() => {}}>
                                {loading ? 'Registering...' : 'Register Guardian'}
                            </Button>
                        </div>
                    </form>
                )}
            </Pane>
        </ProjectLayout>
    )
}
