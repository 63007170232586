import { FunctionComponent } from 'react'
import { ProjectStatisticProps } from './project-statistic'
import { ProjectStatisticMedium } from './ProjectStatisticMedium'
import { ProjectStatisticSmall } from './ProjectStatisticSmall'

export const ProjectStatistic: FunctionComponent<ProjectStatisticProps> = (props) => {
    const { statistic, size } = props
    switch (size) {
        default:
        case 'medium':
            return <ProjectStatisticMedium statistic={statistic} />
        case 'small':
            return <ProjectStatisticSmall statistic={statistic} />
    }
}
