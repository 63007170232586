const sortDirections = ['asc', 'desc'] as const
export type SortDirection = (typeof sortDirections)[number]

export function sortPrimitives<T>(a: T, b: T, direction?: SortDirection): number {
    const isString = typeof a === 'string' && typeof b === 'string'
    const isAscending = (direction ?? 'asc') === 'asc'
    if (isString ? a.toLowerCase() > b.toLowerCase() : a > b) {
        return isAscending ? 1 : -1
    } else if (isString ? a.toLowerCase() < b.toLowerCase() : a < b) {
        return isAscending ? -1 : 1
    } else {
        return 0
    }
}
