import { NavigatorService } from './navigator.service.ts'

export abstract class CoreResponse {
    public raw: Response
    constructor(raw: Response) {
        this.raw = raw
    }

    public asRedirect(): Redirect {
        return this as any as Redirect
    }
}

export class Redirect extends CoreResponse {
    public url: string

    constructor(raw: Response, url: string) {
        super(raw)
        this.url = url
    }
}

export class BlobResponse extends CoreResponse {
    public blob: Blob

    constructor(raw: Response, blob: Blob) {
        super(raw)
        this.blob = blob
    }
}

export class BaseApiService {
    protected baseURL: string

    constructor(baseURL: string) {
        this.baseURL = baseURL
    }

    private async handle(response: Response, endpoint: string, redirect: boolean = true): Promise<CoreResponse | any> {
        if (!response.ok) {
            const text = await response.text().catch(() => {
                return 'Request call to ' + endpoint + ' failed: ' + response.statusText
            })
            switch (response.status) {
                case 400: // no identity
                case 403: {
                    // not logged in
                    if (
                        redirect &&
                        NavigatorService.navigator != null &&
                        (text == '' || text == '/' || window.location.pathname != text)
                    ) {
                        if ((text == '' || text == '/') && window.location.pathname == text) {
                            window.location.reload()
                        } else {
                            NavigatorService.navigator(text)
                        }
                        return
                    }
                    return new Redirect(response, text) // not logged in
                }
                default:
                    throw new Error(text)
            }
        }

        if (response.redirected) {
            return new Redirect(response, response.url)
        } else if (response.type === 'cors') {
            return {}
        } else if (response.type === 'basic') {
            // Some types have moer info, for example charset=utf-8
            let contentType = response.headers.get('content-type')
            const index = contentType?.indexOf(';')
            if (index != -1) {
                contentType = contentType!.substring(0, index)
            }
            switch (contentType) {
                case 'text/plain': {
                    return response.text()
                }
                case 'application/json':
                    return response.json()
                case 'application/octet-stream':
                    return response.blob()
                default: {
                    return response.body
                }
            }
        } else {
            return response.json()
        }
    }

    protected async delete(endpoint: string, params?: URLSearchParams): Promise<any> {
        const url = new URL(endpoint, this.baseURL)
        if (params) url.search = params.toString()
        return fetch(url.toString(), {
            method: 'DELETE',
        }).then(async (response) => {
            return this.handle(response, endpoint)
        })
    }

    protected async get(endpoint: string, params?: URLSearchParams, redirect: boolean = true): Promise<any> {
        const url = new URL(endpoint, this.baseURL)
        if (params) url.search = params.toString()
        return fetch(url.toString(), {}).then(async (response) => {
            return this.handle(response, endpoint, redirect)
        })
    }

    protected async post(endpoint: string, body: object | FormData, content_type: string = 'application/json') {
        const url = new URL(endpoint, this.baseURL)

        const headers = new Headers({})
        if (!(body instanceof FormData)) {
            headers.set('Content-Type', content_type)
        }
        return fetch(url.toString(), {
            method: 'POST',
            headers,
            body: body instanceof FormData ? body : JSON.stringify(body),
        }).then(async (response) => {
            return this.handle(response, endpoint)
        })
    }
}
