import { FunctionComponent, useEffect, useState } from 'react'
import { ProjectActivityTable } from '@components'
import { ProjectLayout } from '@components/Layout/ProjectLayout.tsx'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { IDemiaNotification } from '@utils/types/ProjectDetails.ts'

export const ProjectActivityPage: FunctionComponent = () => {
    const api = createApiService(config.API_BASE_URL)

    const [activities, setActivities] = useState<IDemiaNotification[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getActivities = async () => {
            return await api.context.getNotifications().then((response) => {
                const activities = response.notifications.map((item) => {
                    return {
                        isRead: false,
                        user: item.user,
                        timestamp: new Date(item.timestamp),
                        message: item.message,
                    }
                })
                setLoading(false)
                setActivities(activities)
            })
        }

        getActivities().catch((err) => {
            setLoading(false)
            console.error(err)
        })
    }, [])

    return (
        <ProjectLayout title='Activity'>
            <ProjectActivityTable loading={loading} activities={activities} />
        </ProjectLayout>
    )
}
