import { FunctionComponent, ReactNode } from 'react'
import { Text } from './Text'
import { Icon, IconName } from '@components'

interface InfoTextProps {
    children: ReactNode
}

export const InfoText: FunctionComponent<InfoTextProps> = (props) => {
    const { children } = props
    return (
        <div className='flex flex-row items-center space-x-3'>
            <Icon name={IconName.Info} color='black' />
            <Text type='subtitle2' color='black'>
                {children}
            </Text>
        </div>
    )
}
