import { useNavigate, useParams } from 'react-router-dom'
import { formula } from '@assets'
import { Button } from '@components'
import { ProjectLayout } from '@components/Layout/ProjectLayout.tsx'
import classes from './ProjectAnalyticsParameterCustomizationPage.module.scss'
import { buildProjectRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/app/routing'

const parametersData = [
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: false,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
]

export const ProjectAnalyticsParameterCustomizationPage = () => {
    const { slug } = useParams()
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup).replace(':slug', slug ?? '')
    const navigate = useNavigate()

    const onCancelClick = () => {
        navigate(url)
    }

    return (
        <ProjectLayout title='Customize Parameters'>
            <figure className={classes.imageContainer}>
                <img src={formula} alt='Formula' />
            </figure>
            <div className={classes.content}>
                <div className={classes.innerContent}>
                    <div className={classes.data}>
                        {parametersData.map((item, index) => (
                            <div className={classes.singleItemInput} key={index}>
                                <div className={classes.left}>
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                </div>
                                {item.input ? (
                                    <div className={classes.right}>
                                        <span>Value</span>
                                        <input type='text' name='value' />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row items-center justify-end space-x-3'>
                        <Button variant='text' size='medium' onClick={onCancelClick}>
                            Cancel
                        </Button>
                        <Button size='medium' onClick={() => {}}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </ProjectLayout>
    )
}
