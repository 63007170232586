import { FunctionComponent } from 'react'
import { ProfileSidebarNavigation } from './ProfileSidebarNavigation'
import { Sidebar } from './Sidebar'

export const ProfileSidebar: FunctionComponent = () => {
    return (
        <Sidebar>
            <ProfileSidebarNavigation />
        </Sidebar>
    )
}
