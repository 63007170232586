import { FunctionComponent } from 'react'
import { ProfileAvatar } from '@components/Profile'
import { ITableRowProps, TableRow } from '@components/Table'
import { Text } from '@components/Text'
import { IDemiaNotification } from '@utils/types/ProjectDetails.ts'

export const ProjectActivityTableRow: FunctionComponent<ITableRowProps<IDemiaNotification>> = (props) => {
    const { user = '', message = '', timestamp = new Date() } = props?.data ?? {}
    const textEntries = [message, timestamp.toLocaleString()]
    const cells = [
        <div className='flex flex-row items-center space-x-2'>
            <ProfileAvatar initials={user.substring(0, 2)} />
            <Text type='body1'>{user}</Text>
        </div>,
        ...textEntries.map((entry) => <Text type='body1'>{entry}</Text>),
    ]

    return <TableRow>{cells.map((cell) => cell)}</TableRow>
}
