//import React from 'react'
import ReactDOM from 'react-dom/client'
import { Auth0ProviderWithRedirectCallback } from '@pages/User'
import App from './App'
import './index.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
    //<React.StrictMode>
    <Auth0ProviderWithRedirectCallback>
        <App />
    </Auth0ProviderWithRedirectCallback>
    //</React.StrictMode>
)
