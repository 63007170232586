import { FunctionComponent, ReactNode } from 'react'
import { Text } from '@components/Text'
import classes from './Copyable.module.scss'

interface ICopyableProps {
    children?: ReactNode
    value: string
}

export const Copyable: FunctionComponent<ICopyableProps> = (props) => {
    const { children, value } = props

    function setClipboard(input: string): boolean {
        try {
            const textArea = document.createElement('textarea')
            textArea.value = input
            document.body.appendChild(textArea)
            textArea.select()
            document.execCommand('copy')
            document.body.removeChild(textArea)

            return true
        } catch (err) {
            console.error(err)
            return false
        }
    }

    return (
        <div onClick={() => setClipboard(value)} className={classes.copyable}>
            {children ? children : <Text type='body1'>{value}</Text>}
        </div>
    )
}
