import { FunctionComponent, ReactNode } from 'react'
import { signIn } from '@assets'
import { Logo } from '@components/Logo'
import classes from './OnboardingLayout.module.scss'

interface IOnboardingLayoutProps {
    children: ReactNode | ReactNode[]
}

export const OnboardingLayout: FunctionComponent<IOnboardingLayoutProps> = (props) => {
    const { children } = props
    return (
        <main className={classes.onboardingLayout}>
            <article className='flex flex-col pt-12 px-12 bg-[var(--surface-1)]'>
                <div>
                    <Logo />
                </div>
                <section className={classes.onboardingContent}>
                    <div className='max-w-[620px] p-12 bg-[var(--white)] clipped-xl'>{children}</div>
                </section>
            </article>
            <aside className='h-full'>
                <img className='w-full h-full max-h-[100vh] object-cover' src={signIn} alt='Sign In image' />
            </aside>
        </main>
    )
}
