const themes = ['light', 'dark'] as const
export type Theme = (typeof themes)[number]

export type Style = Record<string | number, string & {}>

export function isDarkPreferred(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
}

export function toggleHtmlRootDarkMode(): void {
    const root = document.documentElement
    if (root.classList.contains('dark')) {
        root.classList.remove('dark')
    } else {
        root.classList.add('dark')
    }
}
