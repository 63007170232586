import { FunctionComponent } from 'react'
import { Text } from '@components/Text'
import { ITableFooterResultsProps } from '../types.ts'

export const TableFooterResults: FunctionComponent<ITableFooterResultsProps> = (props) => {
    const { length, limit, page = 1 } = props

    const start = limit * (page - 1) + 1
    const end = Math.min(start + limit - 1, length)
    const resultsText = length <= limit ? `${length}` : `${start} to ${end} of ${length}`

    if (length === 0) {
        return <Text type='body1'>No results</Text>
    } else {
        return (
            <Text type='body1'>
                Showing {resultsText} {length === 1 ? 'result' : 'results'}
            </Text>
        )
    }
}
