import { useParams } from 'react-router-dom'
import { FunctionComponent } from 'react'
import { IDemiaNotification } from '@utils/types/ProjectDetails.ts'
import { ProjectUserActivity } from './ProjectUserActivity.tsx'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, Text } from '@components'

export const ProjectRecentActivity: FunctionComponent<{ activity: IDemiaNotification[] }> = ({ activity = [] }) => {
    const loaderSlug = useParams().slug
    return (
        <section className='bg-[var(--surface-transparent)] h-[300px] p-4 border-[1px] border-[var(--stroke-light)] overflow-auto'>
            <div className='flex items-center justify-between mb-3 pb-3 border-b-[1px] border-[var(--stroke)]'>
                <Text type='subtitle2'>Recent Activity</Text>
                <Link url={`/projects/${loaderSlug}/activity`}>View all</Link>
            </div>
            <div className='flex flex-col space-y-4'>
                {activity.length > 0 ? (
                    activity.map((i: IDemiaNotification, index: number) => <ProjectUserActivity key={index} {...i} />)
                ) : (
                    <div className='flex flex-col justify-between space-y-4'>
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                        <Skeleton height={36} />
                    </div>
                )}
            </div>
        </section>
    )
}
