import { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { createApiService } from '@lib/core/api'
import { config } from '@constants'
import { Loading } from '@components/Loading/Loading.tsx'
import { AppRoute, buildRoute } from '@lib/app/routing'

export const Auth0ProviderWithRedirectCallback: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const onRedirectCallback = (appState: any) => {
        console.log(appState)
        console.log('Login was registered successfully')
    }

    return (
        <Auth0Provider
            onRedirectCallback={onRedirectCallback}
            domain={config.AUTH0_DOMAIN}
            clientId={config.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            useRefreshTokens={true}
            cacheLocation={'localstorage'}
        >
            {children}
        </Auth0Provider>
    )
}

const UserContext = createContext({ state: {}, actions: {} })

export const UserProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
    const [fetching, setFetching] = useState(false)

    const api = createApiService(config.API_BASE_URL)

    let navigate: NavigateFunction | null = null
    try {
        navigate = useNavigate()
    } catch (e) {
        console.error(e)
        return <UserContext.Provider value={{ state: {}, actions: {} }}>{children}</UserContext.Provider>
    }

    const getToken = async () => {
        if (isAuthenticated && !fetching) {
            setFetching(true)
            api.auth
                .status()
                .then((status) => {
                    if (!status) {
                        getIdTokenClaims()
                            .then((t) => {
                                const token = t ? t.__raw : ''
                                api.auth
                                    .login(user?.email!, token, token)
                                    .then(() => {
                                        // If were here it means login went well
                                        // And we tried to navigate to /
                                        //navigate!('/overview')
                                        setFetching(false)
                                    })
                                    .catch((error) => {
                                        setFetching(false)
                                        console.log(error)
                                    })
                            })
                            .catch((error) => {
                                setFetching(false)
                                console.log(error)
                                window.location.reload()
                            })
                    } else {
                        setFetching(false)
                        navigate ? navigate(buildRoute(AppRoute.Overview)) : window.location.reload()
                    }
                })
                .catch((e) => {
                    console.error('Error ', e)
                    console.log('No status')
                    if (isAuthenticated && user?.email !== undefined) {
                        navigate ? navigate(buildRoute(AppRoute.Overview)) : window.location.reload()
                    } else {
                        navigate ? navigate(buildRoute(AppRoute.SignIn)) : window.location.reload()
                    }
                })
        }

        if (!fetching) {
            return <UserContext.Provider value={{ state: {}, actions: {} }}>{children}</UserContext.Provider>
        } else {
            return (
                <UserContext.Provider value={{ state: {}, actions: {} }}>
                    <Loading />
                </UserContext.Provider>
            )
        }
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            getToken().catch((error) => {
                console.log(error)
                window.location.reload()
            })
        }
    }, [isAuthenticated])

    if (isAuthenticated && user?.email !== undefined) {
        return (
            <UserContext.Provider value={{ state: {}, actions: {} }}>
                {fetching ? <Loading /> : children}
            </UserContext.Provider>
        )
    } else {
        if (isLoading) {
            //window.location.reload();
            if (fetching) {
                return (
                    <UserContext.Provider value={{ state: {}, actions: {} }}>
                        <Loading />
                    </UserContext.Provider>
                )
            }
        }
        return <UserContext.Provider value={{ state: {}, actions: {} }}>{children}</UserContext.Provider>
    }
}
