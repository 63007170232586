import { FunctionComponent } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Icon, IconName, StatisticLabel, Text } from '@components'
import { Style } from '@lib/utils'
import { ProjectStatisticProps } from './project-statistic.ts'

const STATISTIC_TYPE_ICON_MAPPING: Record<string, IconName> = {
    chart: IconName.Chart,
    sustainability: IconName.Sustainability,
}

export const ProjectStatisticMedium: FunctionComponent<Omit<ProjectStatisticProps, 'size'>> = (props) => {
    const { statistic } = props
    const { type, amount, unit, text, percentage } = statistic
    const style: Style = {
        fontFamily: `var(--font)`,
    }

    return (
        <div className='bg-[var(--surface-transparent)] border-[1px] border-[var(--stroke-light)] px-6 py-4'>
            <div className='flex flex-row items-center justify-center space-x-4'>
                {type ? (
                    <Icon name={STATISTIC_TYPE_ICON_MAPPING[type ?? 'sustainability']} color='green' />
                ) : (
                    <div className='h-[40px]'></div>
                )}
                <div className='flex flex-row items-end space-x-2'>
                    {amount && unit ? (
                        <p
                            className='font-[600] text-[32px] leading-8 align-text-bottom text-[var(--black)]'
                            style={{ ...style }}
                        >
                            {amount}
                        </p>
                    ) : (
                        <Skeleton width={120} height={32} />
                    )}
                    {amount && unit ? (
                        <p className='font-[500] text-[24px] leading-6 align-text-bottom text-[var(--grey)]'>{unit}</p>
                    ) : (
                        <Skeleton width={60} height={24} />
                    )}
                </div>
                {percentage ? <StatisticLabel value={percentage} /> : <></>}
            </div>
            <div className='mt-3'>
                {text ? (
                    <Text type='body1' color='grey' justification='center'>
                        {text}
                    </Text>
                ) : (
                    <Skeleton width={180} height={12} />
                )}
            </div>
        </div>
    )
}
