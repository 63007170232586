import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, CheckboxInput, IconName, InfoText, Link, Pane, Text } from '@components'
import { ProjectLayout } from '@components/Layout/ProjectLayout.tsx'
import { buildProjectRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/app/routing'
import classes from './ProjectAnalyticsSetupPage.module.scss'

const PROJECT_ANALYTICS_PARAMETERS = [
    {
        active: true,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: true,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: false,
        slug: 'HGH',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: true,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: true,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: false,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
    {
        active: false,
        slug: 'GHG',
        text: 'Baseline scenario GHG emissions',
    },
]

export const ProjectAnalyticsSetupPage = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.ParameterCustomization).replace(
        ':slug',
        slug ?? ''
    )

    const [analyticsParameters, setAnalyticsParameters] = useState(PROJECT_ANALYTICS_PARAMETERS)

    function toggleAnalyticsParameter(index: number): void {
        setAnalyticsParameters(
            analyticsParameters.map((parameter, _index) => {
                if (index === _index) {
                    return {
                        ...parameter,
                        active: !parameter.active,
                    }
                } else {
                    return parameter
                }
            })
        )
    }

    function onCancelClick(): void {
        navigate(`/projects/${slug}/analytics`)
    }

    function onActivateClick(): void {
        console.log('activating analytics...')
    }

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Set Up Analytics</Text>
                <Button size='medium' icon={IconName.Plus} onClick={() => {}}>
                    Add New
                </Button>
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <div className={classes.analyticsSetup}>
                <Pane padding='top'>
                    <div className='mb-3'>
                        <InfoText>Set up monitoring parameters to activate analytics</InfoText>
                    </div>
                    <div className={classes.dataContainer}>
                        {PROJECT_ANALYTICS_PARAMETERS.map((item, index) => (
                            <div className={classes.singleItem} key={index}>
                                <CheckboxInput
                                    id={index.toString()}
                                    label={item.text}
                                    checked={item.active}
                                    onClick={() => toggleAnalyticsParameter(index)}
                                />
                                <Link
                                    url={`${url.replace(':analyticsSetupSlug', item.slug)}`}
                                    icon={IconName.ChevronRight}
                                    color='black'
                                    disabled={!analyticsParameters[index].active}
                                >
                                    {item.active ? 'Edit parameters' : 'Set up parameters'}
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-end mt-8'>
                        <Button variant='text' size='medium' onClick={onCancelClick}>
                            Cancel
                        </Button>
                        <Button size='medium' onClick={onActivateClick}>
                            Activate
                        </Button>
                    </div>
                </Pane>
            </div>
        </ProjectLayout>
    )
}
