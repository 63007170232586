import { FunctionComponent } from 'react'
import { IconName } from '@components'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { SidebarNavigationLink } from './SidebarNavigationLink.tsx'
import classes from './SidebarNavigation.module.scss'
import { config } from '@constants'

interface IProfileSidebarNavigationProps {}

export const ProfileSidebarNavigation: FunctionComponent<IProfileSidebarNavigationProps> = (_) => {
    return (
        <nav className={classes['navigation']}>
            <SidebarNavigationLink url={buildRoute(AppRoute.Overview)} icon={IconName.Dashboard} text='Overview' />
            <SidebarNavigationLink
                url={buildRoute(AppRoute.AddStream)}
                icon={IconName.Location}
                text='Add Project'
                svgProperties={['stroke']}
            />
            <SidebarNavigationLink
                url={buildRoute(AppRoute.Profile)}
                icon={IconName.Users}
                text='Profile'
                svgProperties={['stroke']}
            />
            {!config.DEMO_MODE && (
                <SidebarNavigationLink
                    url={buildRoute(AppRoute.Identity)}
                    icon={IconName.Document}
                    text='Identity'
                    svgProperties={['stroke']}
                />
            )}
        </nav>
    )
}
