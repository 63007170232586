import { FunctionComponent } from 'react'
import { ITableField, TableTitle, Table } from '@components/Table'
import { ISensorData } from '@lib/app/sensor'
import { SortDirection, sortPrimitives } from '@lib/utils'
import { ProjectSensorDataTableRow } from './ProjectSensorDataTableRow'

interface IProjectSensorDataTableProps {
    loading: boolean
    sensorDataList: ISensorData[]
}

const PROJECT_SENSOR_DATA_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
        sortByDefault: true,
    },
    {
        text: 'Value',
        key: 'value',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.value, b.value, direction),
    },
    {
        text: 'Score',
        key: 'score',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.score, b.score, direction),
    },
    {
        text: 'Address',
        key: 'address',
        sortingFunction: (a: ISensorData, b: ISensorData, direction: SortDirection) =>
            sortPrimitives(a.address, b.address, direction),
    },
]

export const ProjectSensorDataTable: FunctionComponent<IProjectSensorDataTableProps> = (props) => {
    const { loading, sensorDataList } = props

    function getTableTitle(): string {
        return `${sensorDataList.length} ${sensorDataList.length === 1 ? 'Record' : 'Records'}`
    }

    return (
        <Table
            fields={PROJECT_SENSOR_DATA_TABLE_FIELDS}
            data={sensorDataList}
            RowTemplate={ProjectSensorDataTableRow}
            TitleTemplate={() => TableTitle({ title: getTableTitle(), loading })}
            loading={loading}
        />
    )
}
