import { FunctionComponent, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '@components/Button'
import { IconName } from '@components/Icon'
import { SearchInput } from '@components/Inputs'
import { ProfileMenu } from '@components/Profile'
import { FEATURES } from '@features'
import { AppSettingsContext } from '@lib/app/settings'
import { Theme, toggleHtmlRootDarkMode } from '@lib/utils'

export const Header: FunctionComponent = () => {
    const { data, setData } = useContext(AppSettingsContext)
    const navigate = useNavigate()

    const { slug } = useParams()

    function onThemeClick(theme: Theme): void {
        setData({
            ...data,
            theme: theme,
        })
        toggleHtmlRootDarkMode()
    }

    function onNotificationsClick(): void {
        const url = `/projects/${slug}/activity`
        navigate(url)
    }

    return (
        <header className='flex flex-row items-center justify-end px-8 py-4 border-b-[1px] border-[var(--stroke)]'>
            <div className='flex flex-row space-x-4 items-center justify-between'>
                {FEATURES.app.themes.dark.enabled && data.theme === 'light' && (
                    <IconButton icon={IconName.Sun} onClick={() => onThemeClick('dark')} />
                )}
                {FEATURES.app.themes.light.enabled && data.theme === 'dark' && (
                    <IconButton icon={IconName.Moon} onClick={() => onThemeClick('light')} />
                )}
                <IconButton icon={IconName.Notifications} onClick={onNotificationsClick} />
                <SearchInput onChange={() => null}></SearchInput>
            </div>
            <div className='ml-6'>
                <ProfileMenu />
            </div>
        </header>
    )
}
