import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { SensorDashboardContext } from '@utils/types/ProjectDetails.ts'
import { CargoSensor } from './CargoSensor/CargoSensor'
import { Feedstock } from './Feedstock/Feedstock'
import { Flowmeter } from './Flowmeter/Flowmeter'

const CARGO: string = 'cargo'
const FLOW: string = 'flow'
const FEED: string = 'feed'
export type SensorTypes = typeof CARGO | typeof FLOW | typeof FEED

function sensorToTemplate(sensor: string): SensorTypes {
    sensor = sensor.toLowerCase()
    if (sensor.includes('cargo') || sensor.includes('grape')) {
        return CARGO
    } else if (sensor.includes('flow')) {
        return FLOW
    } else if (sensor.includes('feed')) {
        return FEED
    } else {
        return CARGO
    }
}

export const ProjectDataSensorPage = () => {
    const { slug, sensorsSlug } = useParams()
    const api = createApiService(config.API_BASE_URL)
    const [sensor, setSensor] = useState<SensorDashboardContext | undefined>()

    useEffect(() => {
        api.context.getSensor(slug!, sensorsSlug!).then((context) => {
            setSensor(context)
        })
    }, [sensorsSlug])

    const type = sensorToTemplate(sensorsSlug!)
    switch (type) {
        case CARGO:
            return <CargoSensor sensor={sensor} />
        case FLOW:
            return <Flowmeter sensor={sensor} />
        case FEED:
            return <Feedstock sensor={sensor} />
    }
}
