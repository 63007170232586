import { FunctionComponent } from 'react'
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Area, AreaChart } from 'recharts'
import { formatDate, truncateString } from '@lib/utils'
import classes from './Chart.module.scss'
import Skeleton from 'react-loading-skeleton'
import { IconName } from '@components/Icon'
import { Link } from '@components/Link'
import { Text } from '@components/Text'
import { CustomTooltip } from './CustomTooltip'
import { IChartData, IChartProps } from './types'
import { cleanChartData } from './utils'

export const LineAreaChart: FunctionComponent<IChartProps> = (props) => {
    const { title, data, start, end, loading = false, height = 245 } = props
    const filteredData = filterDataByDate(data, start, end)

    function filterDataByDate(data: IChartData[], start: number | Date, end: number | Date) {
        return data.filter((item: { name: string | number | Date }) => {
            const itemDate = new Date(item.name)
            return itemDate >= start && itemDate <= end
        })
    }

    return (
        <div className='bg-[var(--surface-0)] border-[1px] border-[var(--stroke)] shadow-md'>
            {title && !loading && (
                <div className='min-h-[72px] bg-[var(--surface-1)] flex flex-row items-center p-4'>
                    <div className='w-full flex flex-row items-center justify-between space-x-8 text-nowrap'>
                        {title.url ? (
                            <Link url={title.url} icon={IconName.ChevronRight} color='text-primary'>
                                <Text type='subtitle1' color='text-primary'>
                                    {truncateString(title.title, 32)}
                                </Text>
                            </Link>
                        ) : (
                            <Text type='subtitle1' color='text-primary'>
                                {truncateString(title.title, 32)}
                            </Text>
                        )}
                        {title.total && title.label && (
                            <div className='flex flex-row items-end justify-between space-x-2 p-0'>
                                <Text type='header3'>{title.total}</Text>
                                <Text type='subtitle1' color='text-secondary'>
                                    {title.label}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {loading ? (
                <Skeleton height={245} baseColor='white' />
            ) : (
                <div className={classes['sensor-load-container']}>
                    <ResponsiveContainer width='100%' height={height}>
                        <AreaChart
                            data={cleanChartData(filteredData)}
                            width={500}
                            height={300}
                            margin={{
                                top: 8,
                                right: 32,
                                bottom: 40,
                                left: 8,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                type={'category'}
                                domain={['dataMin', 'dataMax']}
                                dataKey='name'
                                fontFamily='SpaceGrotesk'
                                dy={10}
                                angle={-45}
                                textAnchor={'end'}
                                fontSize={10}
                                tickFormatter={formatDate}
                            />
                            <YAxis fontFamily='SpaceGrotesk' dx={-5} />
                            <Area
                                type='monotone'
                                dataKey='uv'
                                stroke='#32FFC2'
                                fill='#32FFC21A'
                                strokeWidth={2}
                                activeDot={{ r: 6 }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    )
}
