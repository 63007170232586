import { TableRow } from '../TableRow'
import { ITableRowProps } from '../types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import classes from './LoadingTableRow.module.scss'

export function LoadingTableRow<T>(props: ITableRowProps<T>) {
    const { colSpan } = props
    return (
        <TableRow colSpan={colSpan ?? 1}>
            {[
                <div key={0} className={classes.loadingSkeletonContainer}>
                    <Skeleton height={32} count={3} />
                </div>,
            ]}
        </TableRow>
    )
}
