import { FunctionComponent } from 'react'
import { Text } from '@components/Text'

interface IPermissionLabelProps {
    permission: string
}

export const PermissionLabel: FunctionComponent<IPermissionLabelProps> = (props) => {
    const { permission } = props
    return (
        <div className='bg-[var(--stroke)] px-2 py-1'>
            <Text type='body2' color='pink'>
                {permission}
            </Text>
        </div>
    )
}
