import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, flowmeter, statusConnected, statusDisconnected } from '@assets'
import { ProjectLayout } from '@components/Layout/ProjectLayout.tsx'
import classes from './Flowmeter.module.scss'
import { FunctionComponent, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../CalendarStyle.scss'
import { SensorDashboardContext } from '@utils/types/ProjectDetails.ts'
import { dateToReadable } from '@lib/utils'
import Skeleton from 'react-loading-skeleton'
import { BarRangeChart, Button, Icon, IconName, Link, Pane, ProjectSensorDataTable, Tabs, Text } from '@components'
import { getSensorDataFromDashboardContext, ISensorData } from '@lib/app/sensor'

const flowDataEmpty = {
    image: flowmeter,
    active: true,
    infoData: [
        {
            title: 'Sensor ID',
            value: '',
        },
        {
            title: 'Accuracy',
            value: '',
        },
        {
            title: 'Manufacturer',
            value: '',
        },
        {
            title: 'Installed on',
            value: '',
        },
        {
            title: 'Model',
            value: '',
        },
        {
            title: 'Last Calibration',
            value: '',
        },
        {
            title: 'Serial Number',
            value: '',
        },
        {
            title: 'Next Calibration',
            value: '',
        },
    ],
}

const flowInfo = {
    source: 'Flowmeter 1',
    lastUpdate: '27 Oct 2023 - 6:45 PM',
    unit: '0.05 Nm3',
}

const flowmeterDataEmpty = [
    {
        img: cargoCurrent,
        text: 'Current load',
        value: 0,
        valueUnit: '',
    },
    {
        img: cargoAverage,
        text: 'Daily average load',
        value: 0,
        valueUnit: '',
    },
    {
        img: cargoCondidence,
        text: 'Data confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const Flowmeter: FunctionComponent<{ sensor: SensorDashboardContext | undefined }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const [isVisible, setIsVisible] = useState(false)

    const exportButtonHandler = () => {
        setIsVisible((prev) => !prev)
    }

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [sensor, setSensor] = useState<SensorDashboardContext>()
    const [flowmeterData, setFlowmeterData] = useState<
        Array<{
            img: string
            text: string
            value: number
            valueUnit: string
        }>
    >(
        flowmeterDataEmpty.map((a) => {
            return { ...a }
        })
    )

    const [flowData, setFlowData] = useState(
        flowDataEmpty.infoData.map((a) => {
            return { ...a }
        })
    )

    const [sensorDataList, setSensorDataList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<
        Array<{
            name: string
            uv: number
            pv: number
            amt: number
        }>
    >([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensor) {
            setGraphData(
                Array.from(Object.values(sensor.sensor.readings)).map((v) => {
                    return {
                        name: v.timestamp,
                        uv: v.value,
                        pv: v.value,
                        amt: v.value,
                    }
                })
            )

            const readings = Array.from(Object.values(sensor.sensor.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensor.sensor.equipment.units)
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensor])

    useEffect(() => {
        if (props.sensor) {
            setSensor(props.sensor)
            const numbers = [...flowmeterData]
            numbers[0].value = Math.round(props.sensor.stateData.realTimeFlow * 100000) / 100000
            // TEMP
            numbers[0].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[1].value = Math.round(props.sensor.stateData.currentDayAvg * 100000) / 100000
            // TEMP
            numbers[1].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[2].value = Math.round(props.sensor.sensor.avgcf * 10000) / 10000
            setFlowmeterData(numbers)

            const newData = [...flowData]
            newData[0].value = props.sensor.sensor.equipment.id
            newData[1].value = props.sensor.sensor.equipment.accuracy + ''
            newData[2].value = props.sensor.sensor.equipment.manufacturer
            newData[3].value = props.sensor.sensor.equipment.installed + ''
            newData[4].value = props.sensor.sensor.equipment.eqType
            // Last calibration
            newData[5].value = 'Never'
            newData[6].value = props.sensor.sensor.equipment.serialNo
            // Next calibration
            newData[7].value = 'Soon'
            setFlowData(newData)
        }
    }, [props.sensor])

    function onExportClick(fileType: 'csv' | 'xlsv'): void {
        console.log(fileType)
    }

    const TitleComponent = () => {
        return (
            <div className='w-full flex flex-row items-center justify-between relative'>
                <Link url={url} icon={IconName.ArrowLeft} color='text-primary' reverse>
                    <Text type='header2'>{sensor ? sensor.sensor.equipment.name : 'Flowmeter'}</Text>
                </Link>
                {activeTab === 1 && (
                    <Button variant='outline' size='medium' onClick={exportButtonHandler}>
                        <Icon name={IconName.Hamburger} />
                    </Button>
                )}
                {isVisible && (
                    <div className={classes.export}>
                        <div className={classes.exportWrapper}>
                            <div className='mb-8'>
                                <Text type='header3'>Export</Text>
                            </div>
                            <div className='w-full flex flex-col items-end justify-between space-y-4'>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('csv')}
                                >
                                    Export to CSV
                                </Button>
                                <Button
                                    variant='outline'
                                    width='3/4'
                                    icon={IconName.Export}
                                    onClick={() => onExportClick('xlsv')}
                                >
                                    Export to XLSV
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className={classes.flowmeterWrapper}>
                    <div className={classes.flowmeterInfo}>
                        <figure className={classes.image}>
                            <img src={flowDataEmpty.image} alt='Flow Image' />
                        </figure>
                        <div className={classes.rightSide}>
                            <img
                                src={flowDataEmpty.active ? statusConnected : statusDisconnected}
                                className={classes.status}
                                alt='Status'
                            />
                            <div className={classes.data}>
                                {flowData.map((data, index) => (
                                    <div className={classes.item} key={index}>
                                        <h6>{data.title}</h6>
                                        <p>{data.value || <Skeleton />}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            {flowmeterData.map((data, index) => (
                                <div
                                    className='w-1/3 bg-[var(--white)] border-[1px] border-[var(--stroke-light)] flex flex-col items-center justify-around p-6 space-y-4'
                                    key={index}
                                >
                                    <img src={data.img} alt='Image' />
                                    <Text type='body1' color='grey'>
                                        {data.text}
                                    </Text>
                                    <h5 className='font-32-28-500'>
                                        {data.value}{' '}
                                        <span className='font-24-28-400 text-[var(--grey)]'>{data.valueUnit}</span>
                                    </h5>
                                </div>
                            ))}
                        </div>
                        <div className={classes.bottom}>
                            <h4>Biogas Flow</h4>
                            <div className={classes.info}>
                                <p>
                                    Source: <span>{flowInfo.source}</span>
                                </p>
                                <p>
                                    Last update:{' '}
                                    <span>
                                        {sensor ? dateToReadable(new Date(sensor.sensor.lastUpdated)) : 'never'}
                                    </span>
                                </p>
                                <p>
                                    Unit: <span>{flowInfo.unit}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        <div className='p-2'>
                            <BarRangeChart
                                title={sensor && { title: sensor.sensor.equipment.name }}
                                data={graphData}
                                loading={loading}
                            />
                        </div>
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={sensorDataList} />
            </Tabs>
        </ProjectLayout>
    )
}
