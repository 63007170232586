import React, { useEffect, useState } from 'react'
import classes from './SubscriberInfo.module.scss'
import { products } from './Stripe.tsx'
import { strToCamelCase } from '@lib/utils'
import { ProjectDetailsPropsType } from '@utils/types'
import { IUserSubscriptionInfo } from '@lib/app/user'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup'

export const SubscriptionInfo: React.FC<{
    customerId: string
    site: ProjectDetailsPropsType
    subscription: IUserSubscriptionInfo
}> = (customerProps) => {
    const api = createApiService(config.API_BASE_URL)
    const subscription = customerProps.subscription
    const [product, setProduct] = useState<any | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(anchor ? null : event.currentTarget)
    }

    const open = Boolean(anchor)
    const id = open ? 'simple-popper' : undefined
    const sendCancelSignal = async (subscriptionId: string) => {
        api.user
            .cancelSubscription(subscriptionId)
            .then(() => {
                window.location.reload()
            })
            .catch((err) => {
                console.error(err)
                setError(err.message)
            })
    }

    useEffect(() => {
        const fetchSubscriptions = async () => {
            if (subscription.metadata['project_id'] === customerProps.site.projectId) {
                const product = products.find((p) => p.productId === subscription.items.data[0].plan['product'])
                if (product) {
                    setProduct(product)
                }
            }
            setLoading(false)
        }

        fetchSubscriptions().catch((err) => setError(err.message))
    }, [customerProps.customerId])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error: {error}</p>

    return (
        <div className={classes.subscriberInfo}>
            <h3 className={classes.title}>{customerProps.site.projectName}</h3>
            <div>
                {subscription && product && (
                    <div className={classes.subscription} key={subscription.id}>
                        <h2>Subscription Type</h2>
                        <p>{product.name}</p>
                        <h3>Status</h3>
                        <p>{strToCamelCase(subscription.status)}</p>
                        <h3>Subscription Expiration</h3>
                        <p>{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                        <h3>Subscription Renewal</h3>
                        <p>{subscription.cancel_at_period_end ? 'Inactive' : 'Active'}</p>
                        {!subscription.cancel_at_period_end && (
                            <button onClick={handleClick}> Cancel Subscription Renewal</button>
                        )}
                        <Popup id={id} open={open} anchor={anchor} style={{ zIndex: 50 }} placement={'bottom'}>
                            <div className={classes.cancelMenu}>
                                <h2>Subscription Cancellation</h2>
                                <h3>{customerProps.site.projectName}</h3>
                                <p>Are you sure you want to cancel your subscription?</p>
                                <button onClick={() => sendCancelSignal(subscription.id)}>
                                    Yes, Stop Subscription Renewal
                                </button>
                                <button onClick={handleClick}>No, Keep Subscription</button>
                            </div>
                        </Popup>
                    </div>
                )}
                {!subscription && <p>No subscriptions found for this site.</p>}
                {subscription && !product && <p>No product found for this subscription.</p>}
            </div>
        </div>
    )
}
