import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, useNavigate } from 'react-router-dom'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer/Footer.tsx'
import { NavigatorService } from '@lib/core/api'

export const ErrorBoundaryLayout = () => {
    // Just needed to update "the component" to trigger a rerender
    const [someKey, setSomeKey] = useState(null)
    const navigate = useNavigate()
    NavigatorService.setNavigator(navigate)

    return (
        <ErrorBoundary
            fallbackRender={Fallback}
            onReset={() => {
                navigate(-1)
                setSomeKey(null)
            }}
            resetKeys={[someKey]}
        >
            <Outlet />
        </ErrorBoundary>
    )
}

export function Fallback({
    error,
    resetErrorBoundary,
}: {
    error: Error
    resetErrorBoundary: (...args: any[]) => void
}) {
    return (
        <main>
            <Header />
            <div role='alert'>
                <p>Something went wrong:</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Go back</button>
            </div>
            <Footer />
        </main>
    )
}
