export enum AppRoute {
    AddStream = 'add-stream',
    Identity = 'identity',
    Overview = 'overview',
    Profile = 'profile',
    Project = 'projects/:slug',
    SignIn = '',
    Register = 'register',
}

export enum ProjectRoute {
    Activity = 'activity',
    Analytics = 'analytics',
    Dashboard = '',
    DataSources = 'data-sources',
    Documentation = 'documentation',
    Users = 'users',
    Guardian = 'guardian',
}

export enum ProjectAnalyticsRoute {
    Details = ':analyticsSlug',
    ParameterCustomization = 'setup/:analyticsSetupSlug',
    Setup = 'setup',
}

export enum ProjectDataSourcesRoute {
    AddSensor = 'add-sensor',
    DataSources = '',
    Sensor = ':sensorsSlug',
}

export enum ProjectGuardianRoute {
    Register = 'register',
}
