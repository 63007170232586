import { ITableRowProps } from './types.ts'

export function TableRow<T>(props: ITableRowProps<T>) {
    const { children = [], colSpan, MenuTemplate } = props
    return (
        <tr className='table-row'>
            {children.map((child, index) => (
                <td
                    key={index}
                    colSpan={colSpan ?? 1}
                    className='table-cell px-0 py-4 text-left border-y-[1px] border-[var(--stroke)]'
                >
                    {child}
                </td>
            ))}
            {MenuTemplate && (
                <td
                    key={children.length}
                    colSpan={colSpan ?? 1}
                    className='table-cell px-0 py-4 border-y-[1px] border-[var(--stroke)]'
                >
                    <MenuTemplate />
                </td>
            )}
        </tr>
    )
}
