import { FunctionComponent, ReactNode } from 'react'
import { Link as _Link } from 'react-router-dom'
import { Icon, IconName } from '@components/Icon'
import { TextJustification } from '@components/Text'
import { Style } from '@lib/utils'
import classes from './Link.module.scss'

interface LinkProps {
    children: ReactNode
    url: string
    search?: Record<string, string>
    hash?: string
    target?: LinkTarget
    justification?: TextJustification
    icon?: IconName
    reverse?: boolean
    color?: string
    disabled?: boolean
}

export enum LinkTarget {
    Blank = 'blank',
    Self = 'self',
    Parent = 'parent',
}

/**
 * Navigates the user within different pages of the application or
 * to an external website.
 *
 * @param props
 */
export const Link: FunctionComponent<LinkProps> = (props) => {
    const {
        children,
        url,
        search,
        hash,
        target,
        justification = 'left',
        icon,
        reverse,
        color = 'pink',
        disabled = false,
    } = props
    const style: Style = {
        textAlign: justification,
        color: `var(--${color})`,
    }

    function buildPath(): string {
        const searchString = new URLSearchParams(search)
        return `${url}${search ? '?' + searchString.toString() : ''}${hash ? '#' + hash : ''}`
    }

    return (
        <_Link
            to={buildPath()}
            target={`_${target ?? LinkTarget.Self}`}
            className={`flex flex-row ${reverse ? 'flex-row-reverse ' + classes.reverse : ''} items-center space-x-4 ${disabled ? classes.disabled : ''} ${classes.link}`}
            style={{ ...style }}
        >
            {children}
            {icon && <Icon name={icon} color={color} />}
        </_Link>
    )
}
