import { FunctionComponent } from 'react'
import classes from './StatisticLabel.module.scss'

interface StatisticLabelProps {
    value: number | string
}

export const StatisticLabel: FunctionComponent<StatisticLabelProps> = (props) => {
    const { value } = props
    if (Number.isNaN(value)) {
        return <></>
    } else {
        const safeValue = Number(value)
        const isPositive = safeValue >= 0
        const prefix = isPositive ? '+' : '-'
        const unit = '%'
        return (
            <span className={classes[isPositive ? 'positive' : 'negative']}>
                {prefix}
                {Math.abs(safeValue)}
                {unit}
            </span>
        )
    }
}
