import { FunctionComponent } from 'react'
import { Text } from '@components/Text'

interface IProfileAvatarProps {
    initials: string
    backgroundColor?: string
    textColor?: string
    size?: AvatarSize
    onClick?: () => void
}

const avatarSizes = ['medium', 'small'] as const
type AvatarSize = (typeof avatarSizes)[number]

const AVATAR_SIZE_DIMENSION_MAPPING: Record<AvatarSize, string> = {
    medium: 'min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px]',
    small: 'min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px]',
}

export const ProfileAvatar: FunctionComponent<IProfileAvatarProps> = (props) => {
    const { initials, backgroundColor, textColor, size, onClick } = props
    const backgroundColorClass = backgroundColor ? `bg-[var(--${backgroundColor})]` : 'bg-[var(--black)]'
    return (
        <div
            className={`${AVATAR_SIZE_DIMENSION_MAPPING[size ?? 'medium']} ${backgroundColorClass} rounded-full flex flex-col items-center justify-center ${onClick && 'cursor-pointer'}`}
            onClick={onClick ?? undefined}
        >
            <Text type='subtitle2' color={textColor ?? 'white'}>
                {initials}
            </Text>
        </div>
    )
}
