import { FunctionComponent } from 'react'
import { ProgressBar } from '@components/Bars'
import { Link } from '@components/Link'
import { ITableRowProps, TableRow } from '@components/Table'
import { Text } from '@components/Text'
import { ISensorSource } from '@lib/app/sensor'
import { ProjectDataSourcesTableMenu } from './ProjectDataSourcesTableMenu'

export const ProjectDataSourcesTableRow: FunctionComponent<ITableRowProps<ISensorSource>> = (props) => {
    const { url, source, inputType, timestamp, dataConfidence, dataType, dataEntry } = props?.data ?? {}
    const cells = [
        <Link url={url ?? ''}>{source}</Link>,
        <Text type='body1'>{inputType}</Text>,
        <Text type='body1'>{timestamp}</Text>,
        <ProgressBar progress={dataConfidence ?? 0} />,
        <Text type='body1'>{dataType}</Text>,
        <Text type='body1'>{dataEntry}</Text>,
    ]

    return <TableRow MenuTemplate={ProjectDataSourcesTableMenu}>{cells.map((cell) => cell)}</TableRow>
}
