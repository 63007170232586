import { useLocation, useParams } from 'react-router-dom'
import { IconName, LineAreaChart, LineChart, Link, Pane, ProjectLayout, Text } from '@components'
import classes from './ProjectAnalyticsDetailsPage.module.scss'
import { formula } from '@assets'
import { useEffect, useState } from 'react'
import { createApiService } from '@lib/core/api'
import { config } from '@constants'
import { IProjectStateValueSet } from '@lib/app/project'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { buildProjectRoute, ProjectRoute } from '@lib/app/routing'
import { IChartTitle } from '@components/Charts/types.ts'

const parametersData = [
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: false,
        value: '20',
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
        value: '20',
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
        value: '20',
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion',
        input: true,
        value: '20',
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
        value: '',
    },
]

export const ProjectAnalyticsDetailsPage = () => {
    const { analyticsSlug, slug } = useParams()
    const url = buildProjectRoute(ProjectRoute.Analytics).replace(':slug', (slug ?? '').toString())
    const location = useLocation()

    const api = createApiService(config.API_BASE_URL)
    const [analytics, setAnalytics] = useState<IProjectStateValueSet | null>(null)

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [startDate, _setStartDate] = useState(thirtyDaysAgo)
    const [endDate, _setEndDate] = useState(new Date())

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        api.context.getAnalyticsFor(slug!, analyticsSlug!).then((value) => {
            if (value) {
                setAnalytics(value)
            }
        })
    }, [location])

    const TitleComponent = () => {
        return (
            <>
                <Link url={url} icon={IconName.ArrowLeft} color='text-primary' reverse>
                    <Text type='header2'>Analytics</Text>
                </Link>
            </>
        )
    }

    function getChartTitle(): IChartTitle | undefined {
        return analytics
            ? {
                  title: analytics.title,
                  total: Math.round(analytics.total * 1000).toString(),
                  label: analytics.label,
              }
            : undefined
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Pane>
                {analytics ? (
                    analyticsSlug === 'e_project' ? (
                        <LineChart
                            title={getChartTitle()}
                            data={Object.values(analytics?.values || []).map((val, index) => {
                                return { name: analytics!.timestamps[index], uv: val, pv: val, amt: val }
                            })}
                            start={startDate}
                            end={endDate}
                            height={245}
                        />
                    ) : (
                        <LineAreaChart
                            title={getChartTitle()}
                            data={Object.values(analytics?.values || []).map((val, index) => {
                                return { name: analytics!.timestamps[index], uv: val, pv: val, amt: val }
                            })}
                            start={startDate}
                            end={endDate}
                            height={245}
                        />
                    )
                ) : (
                    <Skeleton height={245} />
                )}
                <figure className={classes.imageContainer}>
                    <img src={formula} alt='Formula' />
                </figure>
                <div className={classes.data}>
                    {parametersData.map((item, index) => (
                        <div className={classes.singleItemInput} key={index}>
                            <div className={classes.left}>
                                <h4>{item.title}</h4>
                                <p>{item.text}</p>
                            </div>
                            {item.input ? (
                                <div className={classes.right}>
                                    <span>Value</span>
                                    <input type='text' name='value' value={item.value} disabled />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </div>
            </Pane>
        </ProjectLayout>
    )
}
