import { IAppFeatures } from './interfaces'

export const APP_FEATURES: IAppFeatures = {
    themes: {
        light: {
            enabled: true,
        },
        dark: {
            enabled: false,
        },
    },
}
