import { FunctionComponent, ReactNode, useState } from 'react'
import { Text } from '@components/Text'
import classes from './Tabs.module.scss'

interface ITabsProps {
    children: ReactNode[]
    tabHeaders: string[]
    onTabClick?: (index: number) => void
}

export const Tabs: FunctionComponent<ITabsProps> = (props) => {
    const { children, tabHeaders, onTabClick = (_) => {} } = props
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

    function onTabHeaderClick(index: number): void {
        onTabClick(index)
        setActiveTabIndex(index)
    }

    return (
        <div className='flex flex-col space-y-8'>
            <div className='flex flex-row items-center justify-start space-x-4 border-b-[1px] border-[var(--stroke)]'>
                {tabHeaders.map((h, idx) => (
                    <div
                        className={`${classes.hoverable} cursor-pointer hover:border-b-[3px] hover:border-[var(--text-brand-primary)] ${idx === activeTabIndex ? 'border-b-[3px] border-[var(--text-brand-primary)]' : ''}`}
                        onClick={() => onTabHeaderClick(idx)}
                    >
                        <div className={`w-full h-full px-8 py-6 ${idx === activeTabIndex ? 'translate-y-[2px]' : ''}`}>
                            <Text type='subtitle2' color={idx === activeTabIndex ? 'text-primary' : 'text-secondary'}>
                                {h}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            {children[activeTabIndex]}
        </div>
    )
}
