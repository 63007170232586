import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import classes from './ProjectDashboardPage.module.scss'
import { ProjectInfo, ProjectLayout, ProjectRecentActivity, ProjectStatistic } from '@components'
import Skeleton from 'react-loading-skeleton'
import MolinaSensorMap3D from '@components/SitePlan/MolinaSitePlan.tsx'
import { SitePlan } from '@components/SitePlan/SitePlan.tsx'
import { DashboardPropsType, IDemiaNotification } from '@utils/types/ProjectDetails.ts'
import { IProjectStatistic } from '@lib/app/project'

const PROJECT_STATISTICS: IProjectStatistic[] = [
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'Annual GHG emission reductions',
    },
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'GHG emission reductions last 30 day',
    },
    {
        type: 'chart',
        amount: '',
        unit: '%',
        text: 'Data Confidence',
    },
]

export const ProjectDashboardPage = () => {
    const [project, setProject] = useState<DashboardPropsType | null>(null)
    const [activity, setActivity] = useState<IDemiaNotification[]>([])
    const [statistics, setStatistics] = useState<IProjectStatistic[]>([
        { ...PROJECT_STATISTICS[0] },
        { ...PROJECT_STATISTICS[1] },
        { ...PROJECT_STATISTICS[2] },
    ])

    const navigate = useNavigate()
    const api = createApiService(config.API_BASE_URL)
    const { slug } = useParams()

    useEffect(() => {
        const getProject = async () => {
            return slug
                ? await api.context.getSite(slug).then((response) => {
                      setProject(response)
                  })
                : new Error('Not a known project id')
        }

        const getNotifications = async () => {
            return await api.context.getNotifications().then((response) => {
                const nodes = response.notifications.map((item) => {
                    return {
                        isRead: false,
                        user: item.user,
                        timestamp: new Date(item.timestamp),
                        message: item.message,
                    }
                })
                nodes.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                setActivity(nodes)
            })
        }
        getProject()
            .then(() =>
                getNotifications().catch((err) => {
                    console.error(err)
                    navigate('/overview')
                })
            )
            .catch((err) => {
                console.error(err)
                navigate('/')
            })
    }, [slug])

    const parseValue = (value: string) => {
        const parsed = parseFloat(value)
        return parseFloat(parsed.toFixed(1)).toLocaleString()
    }

    useEffect(() => {
        if (project) {
            const ghgAnnual = {
                type: 'sustainability',
                amount: parseValue(project.ghgAnnual.value),
                unit: project.ghgAnnual.unit,
                text: project.ghgAnnual.label,
            }

            const ghg30Day = {
                type: 'sustainability',
                amount: parseValue(project.ghg30Day.value),
                unit: project.ghg30Day.unit,
                text: project.ghg30Day.label,
            }

            const dcf = {
                type: 'chart',
                amount: project.avgDcf ? project.avgDcf : '0',
                unit: '%',
                text: 'Data Confidence',
            }

            setStatistics([ghgAnnual, ghg30Day, dcf])
        }
    }, [project])

    const mapDataToProjectInfo = () => {
        if (project && project.info && project.info) {
            const { projectId, type, methodology, projectDev } = project.info
            return {
                projectInfo: {
                    projectId,
                    type,
                    methodology,
                    projectDev,
                },
                location: project.location,
            }
        } else {
            return {
                projectInfo: {
                    projectId: '',
                    type: '',
                    methodology: '',
                    projectDev: '',
                },
                location: {
                    address: '',
                    lat: 0.0,
                    lon: 0.0,
                },
            }
        }
    }

    return (
        <ProjectLayout title={project ? project.projectName : 'Dashboard'}>
            <div className='flex flex-col space-y-8'>
                <div className={classes.project}>
                    <div>
                        <div className={classes.content}>
                            <div className={classes.innerContent}>
                                <div className={classes.topContent}>
                                    <ProjectInfo {...mapDataToProjectInfo()} />
                                    {activity ? <ProjectRecentActivity activity={activity} /> : <Skeleton />}
                                </div>
                                <div className={classes.statisticWrapper}>
                                    {statistics.map((i: any, index: any) => (
                                        <ProjectStatistic key={index} statistic={i} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {project ? (
                    project.info.projectId === 'BEM' ? (
                        <MolinaSensorMap3D />
                    ) : (
                        <SitePlan />
                    )
                ) : (
                    <Skeleton height={512} />
                )}
            </div>
        </ProjectLayout>
    )
}
