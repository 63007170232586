import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProfileLayout, Pane } from '@components'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { NewProjectRequest } from '@utils/types'
import classes from './AddStreamPage.module.scss'

export const AddStreamPage: FunctionComponent = () => {
    const navigate = useNavigate()
    const api = createApiService(config.API_BASE_URL)
    const [loading, setLoading] = useState<boolean>(false)

    const addProjectHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        if (loading) {
            return
        }
        event.preventDefault()
        setLoading(true)

        const target = event.target as typeof event.target & {
            announcement: { value: string }
            author: { value: string }
            sub_identifier: { value: string }

            siteId: { value: string }
            siteName: { value: string }
            siteAddress: { value: string }
            siteLongitude: { value: string }
            siteLatitude: { value: string }

            projectId: { value: string }
            projectType: { value: string }
            projectDev: { value: string }
            methodology: { value: string }
        }

        const newSite: NewProjectRequest = {
            address: target.announcement.value,
            author: target.author.value,
            subIdentifier: target.sub_identifier.value,
            project: {
                id: target.siteId.value,
                name: target.siteName.value,
                sensors: [],
                location: {
                    address: target.siteAddress.value,
                    lon: parseFloat(target.siteLongitude.value),
                    lat: parseFloat(target.siteLatitude.value),
                },
                project: {
                    projectId: target.projectId.value,
                    type: target.projectType.value,
                    projectDev: target.projectDev.value,
                    methodology: target.methodology.value,
                },
                announcement: target.announcement.value,
            },
        }

        api.context
            .addSite(newSite)
            .then((response) => {
                console.debug('Response from addProject: ', response)
                navigate(`/projects/${target.siteId.value}/`)
            })
            .catch((error) => {
                console.error('Error adding project:', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <ProfileLayout title='Add Project'>
            <Pane>
                <form onSubmit={addProjectHandler} className={classes.form}>
                    <div className={classes.input}>
                        <label htmlFor='announcement'>Announcement</label>
                        <input type='text' name='announcement' id='announcement' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='author'>Author</label>
                        <input type='text' name='author' id='author' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='projectId'>Project ID</label>
                        <input type='text' name='projectId' id='projectId' defaultValue='ID' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='projectType'>Project Type</label>
                        <input type='text' name='projectType' id='projectType' defaultValue='THE type' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='projectDev'>Project Developer</label>
                        <input type='text' name='projectDev' id='projectDev' defaultValue='Dev 1' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='methodology'>Methodology</label>
                        <input type='text' name='methodology' id='methodology' defaultValue='Some acronym' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='sub_identifier'>Sub Identifier</label>
                        <input type='text' name='sub_identifier' id='sub_identifier' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='siteId'>Site ID</label>
                        <input type='text' name='siteId' id='siteId' defaultValue='1' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='siteName'>Site name</label>
                        <input type='text' name='siteName' id='siteName' defaultValue='My Site' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='siteAddress'>Site Address</label>
                        <input type='text' name='siteAddress' id='siteAddress' defaultValue='Street 1a' required />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='siteLongitude'>Site Longitude</label>
                        <input
                            type='number'
                            step='any'
                            name='siteLongitude'
                            id='siteLongitude'
                            defaultValue='0'
                            required
                        />
                    </div>
                    <div className={classes.input}>
                        <label htmlFor='siteLatitude'>Site Latitude</label>
                        <input
                            type='number'
                            step='any'
                            name='siteLatitude'
                            id='siteLatitude'
                            defaultValue='0'
                            required
                        />
                    </div>
                    <button type='submit' className={classes.submitButton}>
                        {loading ? 'Adding...' : 'Add project'}
                    </button>
                </form>
            </Pane>
        </ProfileLayout>
    )
}
