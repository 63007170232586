import { FunctionComponent, MouseEvent, useRef, useState } from 'react'
import { Text } from '@components/Text'
import { Icon, IconName } from '@components/Icon'
import classes from './DropdownInput.module.scss'
import { Popover } from '@components/Popover'

interface IDropdownInputProps {
    items: IDropdownInputItem[]
    disabled?: boolean
}

export interface IDropdownInputItem {
    text: string
    active: boolean
    onClick: () => void
}

export const DropdownInput: FunctionComponent<IDropdownInputProps> = (props) => {
    const { items, disabled = false } = props
    const [showDropdown, setShowDropdown] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const [activeItemIndex, setActiveItemIndex] = useState(0)

    console.log('ITEMS: ', items)

    const inputRef = useRef<HTMLDivElement | null>(null)

    function onDropdownClick(event?: MouseEvent<HTMLDivElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing because outside click handler already hid the menu.
            } else {
                setShowDropdown(!showDropdown)
            }
        } else {
            /**
             * NOTE: If we are here, the function is being called from the outside click
             * handler of the popover component.
             */
            setShowDropdown(false)
            setHadOutsideClick(true)

            /**
             * NOTE: We are setting a timeout here to mitigate the fact that this function gets called twice
             * when closing a menu by clicking the div (first from the outside click handler then from the
             * div on click handler).
             */
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    function onDropdownItemClick(index: number): void {
        setActiveItemIndex(index)
        items[index].onClick()
    }

    return items.length > 0 ? (
        <div
            ref={inputRef}
            onClick={onDropdownClick}
            className={`${classes.dropdown} ${disabled ? 'disabled' : ''} flex flex-row items-center justify-between space-x-2 px-3 py-2 bg-[var(--surface-0)] hover:bg-[var(--surface-1)] border-[1px] border-[var(--stroke)] ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
        >
            <Text type='subtitle1'>{items[activeItemIndex].text}</Text>
            {!disabled && showDropdown && inputRef ? (
                <Popover anchor={inputRef.current as HTMLDivElement} event='click' onOutsideClick={onDropdownClick}>
                    {items.map((item, idx) => (
                        <div
                            className='w-full bg-[var(--surface-0)] hover:bg-[var(--surface-1)] px-4 py-2 text-left whitespace-nowrap cursor-pointer'
                            onClick={() => onDropdownItemClick(idx)}
                        >
                            <Text type='subtitle2' justification='right'>
                                {item.text}
                            </Text>
                        </div>
                    ))}
                </Popover>
            ) : (
                <div></div>
            )}
            <div className={`${showDropdown ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}>
                <Icon name={IconName.ChevronUp} />
            </div>
        </div>
    ) : (
        <></>
    )
}
