import { createBrowserRouter } from 'react-router-dom'
import products from '@assets/json/projects.json'
import { IProject } from '@lib/app/project'
import {
    AppRoute,
    buildProjectRoute,
    buildRoute,
    ProjectAnalyticsRoute,
    ProjectDataSourcesRoute,
    ProjectGuardianRoute,
    ProjectRoute,
} from '@lib/app/routing'
import { ErrorBoundaryLayout } from './ErrorBoundary.tsx'
import { AddStreamPage } from './AddStream'
import { IdentityPage } from './Identity'
import { OverviewPage } from './Overview'
import { ProfilePage } from './Profile'
import {
    AddProjectDataSensorPage,
    RegisterProjectGuardianPage,
    ProjectActivityPage,
    ProjectAnalyticsDetailsPage,
    ProjectAnalyticsPage,
    ProjectAnalyticsParameterCustomizationPage,
    ProjectAnalyticsSetupPage,
    ProjectDashboardPage,
    ProjectDataSensorPage,
    ProjectDataSourcesPage,
    ProjectDocumentationPage,
    ProjectGuardianPage,
    ProjectUsersPage,
} from './Project'
import { RegisterPage, SignInPage } from './Onboarding'
import { UserProvider } from './User'

export const ROUTER = createBrowserRouter([
    {
        element: <ErrorBoundaryLayout />,
        children: [
            {
                path: buildRoute(AppRoute.SignIn),
                element: (
                    <UserProvider>
                        <SignInPage />
                    </UserProvider>
                ),
            },
            { path: buildRoute(AppRoute.Register), element: <RegisterPage /> },
            { path: buildRoute(AppRoute.AddStream), element: <AddStreamPage /> },
            { path: buildRoute(AppRoute.Identity), element: <IdentityPage /> },
            {
                path: AppRoute.Overview,
                element: (
                    <UserProvider>
                        <OverviewPage />
                    </UserProvider>
                ),
            },
            { path: buildRoute(AppRoute.Profile), element: <ProfilePage /> },
            {
                path: buildRoute(AppRoute.Project),
                loader: async ({ params }) => {
                    return {
                        data: (products as unknown as IProject[]).find(
                            (project: IProject) => project.slug === params.slug
                        ),
                        slug: params.slug,
                    }
                },
                children: [
                    { path: buildProjectRoute(ProjectRoute.Dashboard), element: <ProjectDashboardPage /> },
                    { path: buildProjectRoute(ProjectRoute.Activity), element: <ProjectActivityPage /> },
                    { path: buildProjectRoute(ProjectRoute.Analytics), element: <ProjectAnalyticsPage /> },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Details),
                        element: <ProjectAnalyticsDetailsPage />,
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup),
                        element: <ProjectAnalyticsSetupPage />,
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.ParameterCustomization),
                        element: <ProjectAnalyticsParameterCustomizationPage />,
                    },
                    { path: buildProjectRoute(ProjectRoute.DataSources), element: <ProjectDataSourcesPage /> },
                    {
                        path: buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.AddSensor),
                        element: <AddProjectDataSensorPage />,
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.Sensor),
                        element: <ProjectDataSensorPage />,
                    },
                    { path: buildProjectRoute(ProjectRoute.Documentation), element: <ProjectDocumentationPage /> },
                    { path: buildProjectRoute(ProjectRoute.Users), element: <ProjectUsersPage /> },
                    { path: buildProjectRoute(ProjectRoute.Guardian), element: <ProjectGuardianPage /> },
                    {
                        path: buildProjectRoute(ProjectRoute.Guardian, ProjectGuardianRoute.Register),
                        element: <RegisterProjectGuardianPage />,
                    },
                ],
            },
        ],
    },
])
