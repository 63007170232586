import { FunctionComponent, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { getIconFromName, IconName } from '@components/Icon'
import { Text } from '@components/Text'
import classes from './SidebarNavigationLink.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface ISidebarNavigationLinkProps {
    url: string
    icon: IconName
    text: string
    svgProperties?: SvgProperty[]
}

const svgProperties = ['fill', 'stroke'] as const
type SvgProperty = (typeof svgProperties)[number]

export const SidebarNavigationLink: FunctionComponent<ISidebarNavigationLinkProps> = (props) => {
    const { url, icon, text, svgProperties } = props
    const theme = useContext(AppSettingsContext).data.theme
    const _icon = getIconFromName(icon) ?? ''
    const svgClasses = (svgProperties ?? ['fill']).map((prop) => classes[prop]).join(' ')

    return (
        <NavLink
            to={url}
            end
            className={({ isActive }) => `${classes[theme + '-nav-link']} ${isActive ? classes['active'] : ''}`}
        >
            {_icon && (
                <div>
                    <ReactSVG wrapper='span' className={svgClasses} src={_icon}></ReactSVG>{' '}
                </div>
            )}
            <Text type='subtitle2'>{text}</Text>
        </NavLink>
    )
}
