/**
 * TODO: These should NOT be imported from outside the core module.
 * The API should be designed in such a way that the core `api` module
 * does NOT depend on domain-specific or outside-core logic.
 */
import { AuthApiService } from '@lib/core/auth'
import { UserApiService } from '@lib/app/user/user-api.service'
import { ContextApiService } from '@lib/app/project/context-api.service'
import { HederaApiService } from '@lib/app/hedera/hedera-api.service'
import { ApiCache } from './api.cache'
import { IApiService } from './api.types'

export function createApiService(baseUrl: string): IApiService {
    return {
        auth: new AuthApiService(baseUrl),
        user: new UserApiService(baseUrl),
        context: new ContextApiService(baseUrl),
        hedera: new HederaApiService(baseUrl),
        cache: new ApiCache(),
    }
}
