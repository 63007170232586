import { useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { RouterProvider } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '@components/Loading/Loading'
import { IContext } from '@lib/core/context'
import { AppSettingsContext, DEFAULT_APP_SETTINGS, IAppSettings } from '@lib/app/settings'
import { ROUTER } from '@pages/Router'

const App = () => {
    const { isLoading, error } = useAuth0()
    const [appSettings, setAppSettings] = useState<IAppSettings>(DEFAULT_APP_SETTINGS)
    const appSettingsContext: IContext<IAppSettings> = {
        data: appSettings,
        setData: setAppSettings,
    }

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        console.error('Error ', error)
    }

    return (
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <AppSettingsContext.Provider value={appSettingsContext}>
                <RouterProvider router={ROUTER} />
            </AppSettingsContext.Provider>
        </CookiesProvider>
    )
}

export default App
